import React from 'react'
import styled from 'styled-components'
import no from './no.svg'

const Sec = styled.section`
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    // justify-content: center;
   
  
    flex-direction: column;
    @media only screen and (max-width: 998px) {
        min-height: 100vh;
        width: 100%;
        padding: 2rem 0;
        flex-direction: column;
        background-size: auto 100%;
      }
`;

const Width = styled.div`
      width: 1100px;
 
   display: flex;
   flex-direction: column;

    align-items: center;
    justify-content: center;
     padding: 0 0 5rem 0;
    @media only screen and (max-width: 1300px) {
        width: 98%;
    }

    @media only screen and (max-width: 510px) {
      padding: 0 1rem;
      width: 100%;
  }
  
`;



const Card = styled.div`
    background-color:  rgba(34, 40, 51, 1);
    color: #fff;
    border: none;
      padding:  3rem 5rem;
      border-radius: 8px;
    @media only screen and (max-width: 768px) {
        
      }
`;


const H = styled.p`
   
    font-weight: 500;
    font-size: 2rem;
 margin: 0;
    text-align: center;
    @media only screen and (max-width: 768px) {
        
      }
`;


const T = styled.p`
      font-size: 0.9rem;
      font-weight: 300;
      line-height: 1.5;
      color:rgba(130, 137, 151, 1);
    @media only screen and (max-width: 768px) {
        
      }
`;



const Loading = () => {

    return (
        <Sec >
            
          <Width>
            <Card>
                <T>POOL STARTS on March 6th 2022, 8:00 PM UTC</T>
                <H>LOADING</H>
            </Card>
          </Width>
        </Sec>
    )
}

export default Loading
