import React from "react";
import styled from "styled-components";
import no from "./no.svg";

const Sec = styled.section`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: center;

  flex-direction: column;
  @media only screen and (max-width: 998px) {
    min-height: 100vh;
    width: 100%;
    padding: 2rem 0;
    flex-direction: column;
    background-size: auto 100%;
  }
`;

const Width = styled.div`
  width: 1100px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  padding: 0 0 5rem 0;
  @media only screen and (max-width: 1300px) {
    width: 98%;
  }

  @media only screen and (max-width: 510px) {
    padding: 0 1rem;
    width: 100%;
  }
`;

const Button = styled.button`
  color: #fff;
  border: none;
  padding: 0.5rem 3rem;
  border-radius: 8px;
  @media only screen and (max-width: 768px) {
  }
`;

const H = styled.p`
  font-weight: 800;
  font-size: 2rem;
  margin: 0 0 0.2rem 0;
  @media only screen and (max-width: 768px) {
  }
`;

const T = styled.p`
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.5;

  @media only screen and (max-width: 768px) {
  }
`;

const Img = styled.div`
  height: 30rem;
  width: 30rem;
`;

const NoAllocation = () => {
  return (
    <Sec>
      <Width>
        <Img>
          <img src={no} alt="" style={{ height: "100%", width: "100%" }} />
        </Img>
        <H>You don’t have any allocations yet</H>
        <T>
          Allocations for all of the sales that you participated in will show up
          here.
        </T>
        <Button
          style={{ backgroundColor: "rgba(13, 121, 69, 1)" }}
          as="a"
          href="/projects"
        >
          Go to Projects
        </Button>
      </Width>
    </Sec>
  );
};

export default NoAllocation;
