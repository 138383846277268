import React, { useEffect, useState } from "react";
import InnerHome from "./Home";
import axios from "axios";
import multicalls from "../multicall";
import BigNumber from "bignumber.js";
import ido from "../ABI/ido.json";
import tokenAbi from "../ABI/ERC20.json";

import { Image, Shimmer } from "react-shimmer";
import Web3 from "web3";
import moment from "moment";
// interface IHeaderProps {
//   killSession: () => void
//   connected: boolean
//   address: string
//   chainId: number
// }

const Home = () => {
  const [data, setdata] = useState();

  const loadContractData = async (e) => {
    console.log(e);
    const array = data ? data : [];

    for (let i in e) {
      console.log(e[i], "loop");
      if (e[i].contractAddress && e[i].tokenAddress) {
        const calls = [
          // Balance of token in the LP contract

          {
            address: e[i].contractAddress,
            name: "tradeValue",
            params: [],
          },
          {
            address: e[i].contractAddress,
            name: "isSaleFunded",
            params: [],
          },
          {
            address: e[i].contractAddress,
            name: "hasStarted",
            params: [],
          },
          {
            address: e[i].contractAddress,
            name: "endDate",
          },
          {
            address: e[i].contractAddress,
            name: "startDate",
          },
          {
            address: e[i].contractAddress,
            name: "tokensLeft",
          },
          {
            address: e[i].contractAddress,
            name: "availableTokens",
          },
          {
            address: e[i].contractAddress,
            name: "tokensForSale",
          },
          {
            address: e[i].contractAddress,
            name: "isOpen",
          },
          {
            address: e[i].contractAddress,
            name: "individualMaximumAmount",
          },
        ];

        const [
          tradeValue,
          funded,
          started,
          endDate,
          startDate,
          tokenLeft,
          availableTokens,
          tokensForSale,
          isOpen,
          minimumPersonalAllocation,
        ] = await multicalls(ido, calls);

        console.log(new BigNumber(tradeValue).toNumber());
        const web3 = new Web3(Web3.givenProvider);
        console.log(new BigNumber(tradeValue).toNumber());
        let obj = e[i];
        // obj.tokenName = tokenName;

        let tokenContract = await new web3.eth.Contract(
          tokenAbi,
          e[i].tokenAddress
        );
        const name = await tokenContract.methods.name().call();
        const symbol = await tokenContract.methods.symbol().call();
        obj.tokenSymbol = symbol;
        obj.tokenName = name;
        obj.tradeValue = new BigNumber(tradeValue).toNumber();
        obj.funded = funded[0];
        obj.started = started[0];
        obj.endDate = new BigNumber(endDate).toNumber();
        obj.startDate = new BigNumber(startDate).toNumber();
        obj.tokenLeft = new BigNumber(tokenLeft).toNumber();
        obj.availableTokens = new BigNumber(availableTokens).toNumber();
        obj.tokensForSale = new BigNumber(tokensForSale).toNumber();
        obj.totalRaise = tokensForSale - tokenLeft;
        obj.isOpen = isOpen[0];
        obj.minimumPersonalAllocation = new BigNumber(
          minimumPersonalAllocation
        ).toNumber();

        // return obj;
        array.push(obj);
        // console.log(dtata, "array");
      }
    }
    // await e.map(async (single) => {
    //   if (single.contractAddress) {
    //     const calls = [
    //       // Balance of token in the LP contract
    //       {
    //         address: single.contractAddress,
    //         name: 'tradeValue',
    //         params: [],
    //       },
    //       {
    //         address: single.contractAddress,
    //         name: "isSaleFunded",
    //         params: []
    //       },
    //       {
    //         address: single.contractAddress,
    //         name: "hasStarted",
    //         params: []
    //       },
    //       {
    //         address: single.contractAddress,
    //         name: "endDate"
    //       },
    //       {
    //         address: single.contractAddress,
    //         name: "startDate"
    //       },
    //       {
    //         address: single.contractAddress,
    //         name: "tokensLeft"
    //       },
    //       {
    //         address: single.contractAddress,
    //         name: "availableTokens"
    //       },
    //       {
    //         address: single.contractAddress,
    //         name: "tokensForSale"
    //       },
    //       {
    //         address: single.contractAddress,
    //         name: "isOpen"
    //       }
    //     ]

    //     const [
    //       tradeValue,
    //       funded,
    //       started,
    //       endDate,
    //       startDate,
    //       tokenLeft,
    //       availableTokens,
    //       tokensForSale,
    //       isOpen
    //     ] = await multicalls(ido, calls)

    //     console.log(new BigNumber(tradeValue).toNumber());
    //     let obj = single;
    //     obj.tradeValue = new BigNumber(tradeValue).toNumber();
    //     obj.funded = funded[0];
    //     obj.started = started[0];
    //     obj.endDate = new BigNumber(endDate).toNumber();
    //     obj.startDate = new BigNumber(startDate).toNumber();
    //     obj.tokenLeft = new BigNumber(tokenLeft).toNumber();
    //     obj.availableTokens = new BigNumber(availableTokens).toNumber();
    //     obj.tokensForSale = new BigNumber(tokensForSale).toNumber();
    //     obj.totalRaise = tokensForSale - tokenLeft;
    //     obj.isOpen = isOpen[0]
    //     // return obj;
    //     array.push(obj);
    //     // console.log(dtata, "array");
    //   }
    // })
    await setdata(array);
    // return array;
  };

  useEffect(() => {
    return () => {};
  }, [data]);

  const loadData = async () => {
    axios({
      url: "https://polkastarterdb.herokuapp.com/graphql",
      method: "post",
      data: {
        query: `
            query {
              allIdos{
                id
                productLogo
                productName
                price
                description
                tokenAddress
                contractAddress
                images
                vestingSchedule
                status
                listed
                metaData
              }
            }
      `,
      },
    }).then(async (result) => {
      console.log("qwerrrrrrrrrr", result.data);
      // const array = data ? data : [];
      const upcomingArray = result.data.data.allIdos.filter((e) => {
        const data = e?.images && JSON.parse(e?.images);
        return (
          moment(data.startDate) > moment() || moment(data.endDate) > moment()
        );
      });
      console.log(upcomingArray);
      const array = await loadContractData(upcomingArray);
      console.log(array, "array123");
      // result.data.data.allIdos.map(async (e) => {
      //   // console.log(e);
      //   if (e.contractAddress) {
      //     await loadContractData(e);
      //     // const array = data ? data : [];
      //     // return obj;
      //   }
      // })
      // array.push(obj);
      // console.log(dtata, "array");
      // await setdata(array);
      // console.log(obj);
    });
  };

  useEffect(async () => {
    loadData();
  }, []);
  return (
    <div>
      {data && <InnerHome data={data} />}

      {!data && (
        <div>
          {/* <InnerHome data={data} /> */}
          <Shimmer width={"100%"} height={600} />
          {/* <Image style={{ width: "20%", margin: "auto" }} fallback={<Shimmer width={800} height={600} />} src="https://acegif.com/wp-content/uploads/loading-36.gif" /> */}
        </div>
      )}
    </div>
  );
};

export default Home;
