import React, { useEffect, useState } from "react";
import { Shimmer } from "react-shimmer";
import ipfs from "../../ipfs";
import imgg from "../Home/download.jpeg";

function GetImage(props) {
  const [img, setimg] = useState(imgg);

  async function getImage(hash) {
    // console.log(hash, "hash");

    const result = await ipfs.get(`/ipfs/${hash}`);

    console.log(result[0].content.toString("base64"), "results");

    setimg(result[0].content.toString("base64"));
  }

  useEffect(async () => {
    if (props.hash) {
      await getImage(props.hash);
    }
    return () => {};
  }, []);

  return (
    <>
      {img && (
        <img
          src={`data:image/png;base64,${img}`}
          style={{ width: "100%", height: "100%" }}
        />
      )}
      {!img && (
        <div>
          <Shimmer width={"100%"} height={100} />
          {/* <img style={{ width: "70px", margin: "auto" }} src="https://acegif.com/wp-content/uploads/loading-36.gif" /> */}
        </div>
      )}
    </>
  );
}

export default GetImage;
