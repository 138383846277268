import React, { useState } from "react";
import styled from "styled-components";
import Hbg from "./headerbg.png";
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

const Sec = styled.section`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;

  @media only screen and (max-width: 1620px) {
    align-items: flex-end;
    padding: 0 2rem 0 0;
  }

  @media only screen and (max-width: 1500px) {
    align-items: center;
    padding: 0 0 0;
  }

  @media only screen and (max-width: 998px) {
    min-height: 100vh;
    width: 100%;
    padding: 2rem 0;
    flex-direction: column;
    background-size: auto 100%;
  }
`;

const Width = styled.div`
  width: 1100px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  padding: 0 0 5rem 0;
  margin: 0 0 0 10rem;
  @media only screen and (max-width: 1500px) {
    margin: 0 0 0 0;
  }
  @media only screen and (max-width: 1300px) {
    width: 98%;
  }
  @media only screen and (max-width: 510px) {
    padding: 0 1rem;
    width: 100%;
  }
`;

const StyleCard = styled.div`
  width: 1600px;
  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr));

  @media only screen and (max-width: 1600px) {
    width: 98%;
  }
  @media only screen and (max-width: 510px) {
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
`;

const Heading = styled.div`
  color: rgba(2, 169, 92, 1);
  font-size: 1.6rem;

  background-size: 100% 100%;
  background-repeat: no-repeat;
  @media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 1.2rem;
    font-weight: bold;
  }
`;

const Background = styled.div`
  background-color: rgba(0, 29, 35, 1);
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0 0 0;
  position: relative;
`;

const Dashboard = styled.div`
  background-color: rgba(2, 169, 92, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1300px;
  padding: 2rem 0;
  border-radius: 1.2rem;

  margin: 3rem 0 -6rem 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

  @media only screen and (max-width: 1350px) {
    width: 98%;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
  }

  @media only screen and (max-width: 1093px) {
    margin: 3rem 0 -12rem 0;
  }
`;

const H = styled.p`
  font-weight: 500;
  font-size: 2rem;
  margin: 0 0 0.2rem 0;
  @media only screen and (max-width: 768px) {
  }
`;

const SmallH = styled.p`
  font-weight: 500;
  font-size: 1.7rem;
  margin: 0 0 0.2rem 0;
  @media only screen and (max-width: 768px) {
  }
`;

const T = styled.p`
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.5;

  @media only screen and (max-width: 768px) {
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(50, 58, 73, 1);
  background-image: url(${Hbg});
  height: 15rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;
const Row = styled.div`
  display: flex;
  // margin: 1rem 0 0 2rem;
`;
const HeadContent = styled.div`
  position: fixed;
  top: 45%;
  left: 3rem;
  transform: translate(0, -50%);

  * {
    cursor: pointer;
  }

  @media only screen and (max-width: 1500px) {
    display: none;
  }
`;

const Privacy = () => {
  const scrollToWebsite = () => {
    scroller.scrollTo("scroll-to-element", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -30,
    });
  };

  const scrollToPersonal = () => {
    scroller.scrollTo("scroll-to-personal", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -30,
    });
  };

  const scrollToCollection = () => {
    scroller.scrollTo("scroll-to-Collection", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -30,
    });
  };

  const scrollToDirect = () => {
    scroller.scrollTo("scroll-to-Direct", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -30,
    });
  };

  const scrollToManage = () => {
    scroller.scrollTo("scroll-to-Manage", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -30,
    });
  };

  const scrollToSecurity = () => {
    scroller.scrollTo("scroll-to-Security", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -30,
    });
  };

  const scrollToAccess = () => {
    scroller.scrollTo("scroll-to-Access", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -30,
    });
  };

  const scrollToUse = () => {
    scroller.scrollTo("scroll-to-Use", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -30,
    });
  };

  const scrollToContact = () => {
    scroller.scrollTo("scroll-to-Contact", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -30,
    });
  };

  const scrollToUpdates = () => {
    scroller.scrollTo("scroll-to-Updates", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -30,
    });
  };

  const [active, isSetActive] = useState();

  return (
    <Sec>
      <Width>
        <Header>
          <div>
            <H>Privacy Policy</H>
            <T>
              Last updated:{" "}
              <span style={{ fontWeight: "400" }}>March 3, 2022</span>{" "}
            </T>
          </div>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#222833" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11 15V13C11 11.6739 11.5268 10.4021 12.4645 9.46447C13.4021 8.52678 14.6739 8 16 8C17.3261 8 18.5979 8.52678 19.5355 9.46447C20.4732 10.4021 21 11.6739 21 13V15C21.5304 15 22.0391 15.2107 22.4142 15.5858C22.7893 15.9609 23 16.4696 23 17V22C23 22.5304 22.7893 23.0391 22.4142 23.4142C22.0391 23.7893 21.5304 24 21 24H11C10.4696 24 9.96086 23.7893 9.58579 23.4142C9.21071 23.0391 9 22.5304 9 22V17C9 16.4696 9.21071 15.9609 9.58579 15.5858C9.96086 15.2107 10.4696 15 11 15ZM19 13V15H13V13C13 12.2044 13.3161 11.4413 13.8787 10.8787C14.4413 10.3161 15.2044 10 16 10C16.7956 10 17.5587 10.3161 18.1213 10.8787C18.6839 11.4413 19 12.2044 19 13Z"
              fill="#62CE9A"
            />
          </svg>
        </Header>

        <HeadContent>
          <T onClick={() => scrollToWebsite()}>Website Use</T>
          <T onClick={() => scrollToPersonal()}>Personal Information</T>
          <T onClick={() => scrollToCollection()}>Collection Purposes</T>
          <T onClick={() => scrollToDirect()}>
            Direct Marketing and Opting Out
          </T>
          <T onClick={() => scrollToManage()}>Management of Information</T>
          <T onClick={() => scrollToSecurity()}>
            Security of Personal Information
          </T>
          <T onClick={() => scrollToAccess()}>Access to Personal Information</T>
          <T onClick={() => scrollToUse()}>Use of Website</T>
          <T onClick={() => scrollToContact()}>Contact Details</T>
          <T onClick={() => scrollToUpdates()}>
            Updates to this Privacy Policy
          </T>
        </HeadContent>

        <div style={{ margin: "2rem 0 0 0" }}>
          <T>
            Herein is provided the privacy policy of Polkastarter (owner and
            operator of Polkastarter.com) This Privacy Policy describes how
            Polkastarter.com will manage end user’s personal information which
            we collect as a result of your use of Polkastarter.com, your use of
            our websites located at https://polkastarter.com and any pages or
            websites under the 'Polkastarter' brand including any tablet or
            mobile phone applications that are owned and/or operated by us or
            whenever we otherwise deal with you. Polkastarter is committed to
            ensuring that the personal information we collect about you is
            protected and is used, stored and disclosed in accordance with the
            Privacy Act and this Privacy Policy.
          </T>
        </div>

        <Element
          name="scroll-to-element"
          style={{ width: "100%", margin: "2rem 0 0 0" }}
        >
          <SmallH style={{ textAlign: "left" }}>Website Use</SmallH>
          <T>
            In using the website, you agree to be bound by the terms of this
            Privacy Policy. Polkastarter.com may review the Privacy Policy
            periodically and reserves the right to change the Privacy Policy at
            any time at its discretion by posting such changes on the website.
            Changes will be effective immediately when posted on the website.
            The end users’ continued use of the website following posting will
            constitute your acceptance of those changes.
          </T>
          <T style={{ margin: "2rem 0 0 0" }}>
            This Privacy Policy will not apply to websites which are linked to
            the website and over which Polkastarter.com has no control.
          </T>
        </Element>

        <Element
          name="scroll-to-personal"
          style={{ width: "100%", margin: "2rem 0 0 0" }}
        >
          <SmallH style={{ textAlign: "left" }}>Personal Information</SmallH>
          <T>
            The kinds of Personal Information that Polkastarter.com may collect
            from you is:
          </T>
          <T style={{ color: "rgba(92, 103, 124, 1)" }}>
            - Name
            <br />
            Email address
            <br />
            Personally submitted preferences
            <br />
            Location data
            <br />
            IP address
          </T>
        </Element>

        <Element
          name="scroll-to-Collection"
          style={{ width: "100%", margin: "2rem 0 0 0" }}
        >
          <SmallH style={{ textAlign: "left" }}>Collection Purposes</SmallH>
          <T>
            Polkastarter.com will collect your Personal Information only by
            lawful and fair means and not in an intrusive way to operate its
            Service, and to provide you with the following information:
          </T>
          <ul>
            <li>
              If you wish to subscribe to Polkastarter.com's weekly newsletter
              or other marketing communications.
            </li>
            <li>To update you on technical progress of Polkastarter.</li>
            <li>To provide services to you as a User.</li>
            <li>
              To identify you as a User for security purposes and to comply with
              our legal obligations.
            </li>
            <li>To maintain your User account.</li>
            <li>
              To upgrade and enhance your experience within the website or over
              the telephone, or to tailor or develop information, services or
              products to suit your needs which may include market research and
              conducting promotions.
            </li>
            <li>
              To tell you about our products or services that we think may be of
              interest to you by communicating with you via email, SMS or
              telephone.
            </li>
            <li>
              To create aggregate data about Users through demographic
              profiling, statistical analysis of the database to provide to
              potential and existing Polkastarter holders, and to allow for more
              efficient operation of Polkastarter.com’s business.
            </li>
            <li>
              To comply with Polkastarter.com’s legal and statutory obligations.
            </li>
            <li>
              For taking appropriate action if Polkastarter.com has reason to
              suspect that unlawful activity or misconduct of a serious nature
              has been, is being or may be engaged in that relates to our
              functions and activities.
            </li>
            <li>To establish, exercise or defend any legal claims.</li>
            <li>Manage job applications.</li>
          </ul>
        </Element>

        <div>
          <T>
            You may choose to deal with us on an anonymous basis or to use a
            pseudonym unless it is not practicable for us to deal with
            individuals who we cannot identify or we are required by law to only
            deal with identified individuals. Also, if you do not provide
            Polkastarter.com with the Personal Information we request, we may be
            unable to process your request to become a User, provide you with
            our services or respond to your enquiry or complaint. Examples of
            Personal Information that may be collected by Polkastarter.com
            include your name, email address, telephone numbers, your date of
            birth, other verification information such as your Service usage
            activities. By becoming a User or otherwise choosing to provide
            Polkastarter.com with Personal Information you consent to
            Polkastarter.com collecting, using and disclosing your Personal
            Information for the above purposes.
          </T>
          <T>
            We use Hotjar in order to better understand our users 'needs and to
            optimize this service and experience. Hotjar is a technology service
            that helps us better understand our users' experience (eg how much
            time they spend on which pages, which links they choose to click,
            what users do and don't like, etc.) and this enables us to build and
            maintain our service with user feedback. Hotjar uses cookies and
            other technologies to collect data on our users' behavior and their
            devices. This includes a device's IP address (processed during your
            session and stored in a de identified form), device screen size,
            device type (unique device identifiers), browser information,
            geographic location (country only), and the preferred language used
            to display our website. Hotjar stores this information on our behalf
            in a pseudonymized user profile.Hotjar is contractually forbidden to
            sell any of the data collected on our behalf. For further details,
            please see the 'about Hotjar' section of Hotjar's support site.
          </T>
        </div>

        <Element
          name="scroll-to-Direct"
          style={{ width: "100%", margin: "2rem 0 0 0" }}
        >
          <SmallH style={{ textAlign: "left" }}>
            Direct Marketing and Opting Out
          </SmallH>
          <T>
            Occasionally we may use your Personal Information to inform you
            about Polkastarter.com's products or services or about promotional
            activities which Polkastarter.com believes may be of interest or of
            benefit to you. We may do this via email, SMS, telephone or mail. If
            you no longer wish to receive marketing or promotional material from
            Polkastarter.com at all or in any particular form, you may contact
            us at any time by email to support@Polkastarter.com with your
            request with which we will comply as soon as is practical.
          </T>
          <T style={{ margin: "2rem 0 0 0" }}>
            From time to time we may contact you in relation to the management
            and administration of your Polkastarter.com account. These
            communications can be via any of the modes of contact recorded when
            registering as a User. Such communication does not affect your
            opt-in or opt-out status for direct marketing communications.
          </T>
        </Element>

        <Element
          name="scroll-to-Manage"
          style={{ width: "100%", margin: "2rem 0 0 0" }}
        >
          <SmallH style={{ textAlign: "left" }}>
            Management of your Personal Information
          </SmallH>
          <T>
            Polkastarter.com will take all reasonable steps to ensure that the
            Personal Information which it collects, uses or discloses is correct
            and is stored in a secure environment which is accessed only by
            authorised persons.
          </T>
          <T style={{ margin: "2rem 0 0 0" }}>
            Polkastarter.com will destroy or permanently de-identify the
            Personal Information we hold when it is no longer required for any
            purpose permitted under the APPs including our legal or operational
            obligations.
          </T>
        </Element>
        {/* <Element name="scroll-to-element" className="element">
            Scroll to element
        </Element> */}
        <Element
          name="scroll-to-Security"
          style={{ width: "100%", margin: "2rem 0 0 0" }}
        >
          <SmallH style={{ textAlign: "left" }}>
            Security of Personal Information
          </SmallH>
          <T>
            You acknowledge that no data transmission over the Internet is
            totally secure. Accordingly, Polkastarter.com does not warrant the
            security of any information which you transmit to it. Any
            information which you transmit to Polkastarter.com is transmitted at
            your own risk. However, once Polkastarter.com receives your
            transmission, Polkastarter.com will take reasonable steps to protect
            your Personal Information from misuse, loss and unauthorised access,
            modification and disclosure including by using password protected
            systems and databases and Secure Socket Layer (SSL) technology.
          </T>
          <T>
            Polkastarter.com’s employees, agents and contractors are required to
            maintain the confidentiality of Users’ Personal Information and
            trading behaviour.
          </T>
          <T>
            Information posted on bulletin boards or communicated within a
            social media environment (for example, Facebook, Twitter, Chat
            Rooms) becomes public information. Polkastarter.com cannot guarantee
            the security of this type of disclosed information.
          </T>

          <T>
            We take seriously the responsibility to exclude children from access
            to our services. We will not accept their information for the
            purposes of accessing or using the Service. It is however,
            ultimately the responsibility of parents or guardians to monitor
            their children’s Internet activities including where appropriate by
            using Internet screening software.
          </T>
          <T>
            Remember to always log out of your account when you have completed
            your time on the website. This is particularly important if you
            share a computer with another person. You are responsible for the
            security of and access to your own computer, mobile device or any
            other handset used to access the website.
          </T>

          <T>
            Ultimately, you are solely responsible for maintaining the secrecy
            of your username, password and any account information. Please be
            careful whenever using the Internet and our website.
          </T>
        </Element>

        <Element
          name="scroll-to-Access"
          style={{ width: "100%", margin: "2rem 0 0 0" }}
        >
          <SmallH style={{ textAlign: "left" }}>
            Access to Personal Information
          </SmallH>
          <T>
            You may access the Personal Information collected by
            Polkastarter.com by contacting us at support@Polkastarter.com. We
            will give you access to your Personal Information in the manner
            requested if that is possible and within a reasonable period. If we
            refuse your request or cannot give you access in the manner you have
            requested, we will do what we can to meet your requirements by other
            means. We may not be required to give you access to your Personal
            Information in certain circumstances which are set out in the APPs
            including where it may have an unreasonable impact on other
            individual's privacy. If we refuse access for such reasons, we will
            advise you in writing of the refusal and our reasons and the
            complaint mechanisms available to you.
          </T>
        </Element>

        <Element
          name="scroll-to-Use"
          style={{ width: "100%", margin: "2rem 0 0 0" }}
        >
          <SmallH style={{ textAlign: "left" }}>Use of Website</SmallH>
          <T>
            By using this website, you agree to Polkastarter.com collecting and
            processing your Personal Information on the basis explained in this
            Privacy Policy.
          </T>
        </Element>

        <Element
          name="scroll-to-Contact"
          style={{ width: "100%", margin: "2rem 0 0 0" }}
        >
          <SmallH style={{ textAlign: "left" }}>Contact Details</SmallH>
          <T>
            If you have any queries, requests for access or correction or
            complaints relating to the handling of your personal information,
            please contact us by email.
          </T>
          <T style={{ color: "green" }}>support@polkastarter.com</T>
        </Element>

        <Element
          name="scroll-to-Updates"
          style={{ width: "100%", margin: "2rem 0 0 0" }}
        >
          <SmallH style={{ textAlign: "left" }}>
            Updates to this Privacy Policy
          </SmallH>
          <T>
            Polkastarter.com may review, change and update this Privacy Policy
            to reflect our current practices and obligations. We will publish
            our current Privacy Policy on our website at www.Polkastarter.com
            and the changes will take effect at the time of publishing. You
            should review this privacy policy regularly and remain familiar with
            its terms.
          </T>
        </Element>
      </Width>
    </Sec>
  );
};

export default Privacy;
