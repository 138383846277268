import React, { useState } from "react";
import axios from 'axios';
import { useWallet } from "use-wallet";

function WhitelistFrom(props) {

  const wallet = useWallet();


  const [metaData, setmetaData] = useState({
    name: "",
    email: "",
    walletAddress: "",
    twitter: "",
    telegram: "",
    tnc: false,
    updates: false,
  })




  console.log(props, "props");
  const handleSubmit = async () => {
    if (metaData.name && metaData.email && metaData.telegram && metaData.twitter) {
      await axios({
        url: "https://polkastarterdb.herokuapp.com/graphql",
        method: "post",
        data: {
          query: `
              mutation CreateWhiteList($contractAddress:String!, $metaData:String, $ido:String){
                createWhitelist(whitelist:{
                  contractAddress:$contractAddress,
                  metaData:$metaData,
                  ido:$ido
                }){
                  id
                  contractAddress
                  metaData
                  ido
                }
              }
        `,
          variables: {
            contractAddress: wallet.account,
            metaData: metaData.toString(),
            ido: window.location.search.split("=")[1]
          },
        },
      }).then((result) => { 

          setTimeout(() => {
              window.location.reload()
          }, 3000);

      })
    }
  }
  return (
    <div id="app-content">
      <div
        class="ps--whitelist"
        data-controller="whitelist tooltip project-partial applications"
        data-id="93"
        data-form-id="97"
      >
        <div class="ps--whitelist__header">
          <h1 class="ps--whitelist__header__title">
            Allowlist Application Form for Cryptoverse
          </h1>
          <div class="ps--project-show__status">
            <div
              class="ps--project-show__date"
              data-project-partial-target="nextDateHolder"
            >
              <span data-project-partial-target="nextDatePrefix">
                Applications close on{" "}
              </span>
              <span
                data-project-partial-target="nextDateLabel"
                data-partial-type="topNav"
              >
                March 6th 2022, 8:00 PM UTC
              </span>
              <div
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                title=""
                data-controller="tooltip"
                data-project-partial-target="nextDateTooltip"
                class="d-none"
                data-bs-original-title="12 Aug 2021, 12:00 PM UTC"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM9 12H7V7H9V12ZM8 6C7.4 6 7 5.6 7 5C7 4.4 7.4 4 8 4C8.6 4 9 4.4 9 5C9 5.6 8.6 6 8 6Z"
                    fill="#212121"
                  ></path>
                </svg>
              </div>
            </div>
            <div
              class="ps--badge ps--info"
              data-project-partial-target="status"
            >
              Allowlist Open
            </div>
            <div
              class="ps--project-show__share"
              data-controller="project-social"
              data-id="93"
            >
              <button
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M17 6L12 0L7 6H11V17H13V6H17Z" fill="#828997"></path>
                  <path
                    d="M22 23H2C1.84623 23 1.69452 22.9646 1.55667 22.8965C1.41882 22.8283 1.29854 22.7293 1.20519 22.6071C1.11183 22.4849 1.04791 22.3429 1.01839 22.192C0.988873 22.041 0.994556 21.8854 1.035 21.737L4.035 10.737C4.09271 10.5253 4.21845 10.3385 4.39283 10.2053C4.56722 10.0721 4.78057 9.99996 5 10H8V12H5.764L3.31 21H20.69L18.236 12H16V10H19C19.2194 9.99996 19.4328 10.0721 19.6072 10.2053C19.7815 10.3385 19.9073 10.5253 19.965 10.737L22.965 21.737C23.0054 21.8854 23.0111 22.041 22.9816 22.192C22.9521 22.3429 22.8882 22.4849 22.7948 22.6071C22.7015 22.7293 22.5812 22.8283 22.4433 22.8965C22.3055 22.9646 22.1538 23 22 23Z"
                    fill="#828997"
                  ></path>
                </svg>
              </button>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <a
                    class="dropdown-item"
                    target="_blank"
                    data-project-social-target="reddit"
                    href="http://www.reddit.com/submit?url=https://allowlist.cryptoverse.vip/"
                  >
                    Reddit
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="33"
                      height="32"
                      viewBox="0 0 33 32"
                      fill="none"
                    >
                      <path
                        d="M16.4895 32C25.3261 32 32.4895 24.8366 32.4895 16C32.4895 7.16344 25.3261 0 16.4895 0C7.65295 0 0.489502 7.16344 0.489502 16C0.489502 24.8366 7.65295 32 16.4895 32Z"
                        fill="#FF4500"
                      ></path>
                      <path
                        d="M27.1562 16C27.1562 14.7088 26.1082 13.6608 24.817 13.6608C24.1808 13.6608 23.6194 13.9041 23.2077 14.3158C21.617 13.1743 19.4088 12.4257 16.9761 12.3322L18.0427 7.33566L21.5047 8.06549C21.5422 8.94502 22.272 9.65613 23.1702 9.65613C24.0872 9.65613 24.8357 8.90759 24.8357 7.99063C24.8357 7.07367 24.0872 6.32513 23.1702 6.32513C22.5153 6.32513 21.9539 6.6994 21.6919 7.26081L17.8182 6.43742C17.7059 6.4187 17.5936 6.43742 17.5001 6.49356C17.4065 6.5497 17.3503 6.64326 17.3129 6.75554L16.134 12.3322C13.6451 12.407 11.4182 13.1368 9.80883 14.3158C9.39713 13.9228 8.81702 13.6608 8.19947 13.6608C6.90824 13.6608 5.86029 14.7088 5.86029 16C5.86029 16.9544 6.42169 17.7591 7.24509 18.1333C7.20766 18.3579 7.18895 18.6012 7.18895 18.8444C7.18895 22.4374 11.362 25.338 16.527 25.338C21.6919 25.338 25.865 22.4374 25.865 18.8444C25.865 18.6012 25.8463 18.3766 25.8088 18.152C26.5761 17.7778 27.1562 16.9544 27.1562 16ZM11.1562 17.6655C11.1562 16.7485 11.9047 16 12.8217 16C13.7387 16 14.4872 16.7485 14.4872 17.6655C14.4872 18.5824 13.7387 19.331 12.8217 19.331C11.9047 19.331 11.1562 18.5824 11.1562 17.6655ZM20.4568 22.0631C19.3153 23.2047 17.1445 23.2795 16.5082 23.2795C15.872 23.2795 13.6825 23.186 12.5597 22.0631C12.3913 21.8947 12.3913 21.614 12.5597 21.4456C12.7281 21.2772 13.0088 21.2772 13.1772 21.4456C13.8884 22.1567 15.4229 22.4187 16.527 22.4187C17.6311 22.4187 19.1468 22.1567 19.8767 21.4456C20.0451 21.2772 20.3258 21.2772 20.4942 21.4456C20.6252 21.6327 20.6252 21.8947 20.4568 22.0631ZM20.1574 19.331C19.2404 19.331 18.4919 18.5824 18.4919 17.6655C18.4919 16.7485 19.2404 16 20.1574 16C21.0743 16 21.8229 16.7485 21.8229 17.6655C21.8229 18.5824 21.0743 19.331 20.1574 19.331Z"
                        fill="white"
                      ></path>
                    </svg>
                  </a>{" "}
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    target="_blank"
                    data-project-social-target="twitter"
                    href="http://twitter.com/share?text=Check out the Cryptoverse @polkastarter IDO 🚀&amp;url=https://allowlist.cryptoverse.vip/"
                  >
                    Twitter
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="33"
                      height="32"
                      viewBox="0 0 33 32"
                      fill="none"
                    >
                      <g clip-path="url(#svg-twitter)">
                        <path
                          d="M29.1282 9.61809C29.1476 9.89809 29.1476 10.1781 29.1476 10.4607C29.1476 19.071 22.5927 29.0013 10.6069 29.0013V28.9962C7.06627 29.0013 3.59918 27.9871 0.61853 26.0749C1.13337 26.1368 1.65079 26.1678 2.1695 26.1691C5.10369 26.1716 7.95401 25.1871 10.2624 23.3742C7.47401 23.3213 5.02885 21.5033 4.17466 18.8491C5.15143 19.0374 6.15789 18.9987 7.11659 18.7368C4.07659 18.1226 1.8895 15.4516 1.8895 12.3497C1.8895 12.3213 1.8895 12.2942 1.8895 12.2671C2.7953 12.7716 3.8095 13.0516 4.84692 13.0826C1.98369 11.1691 1.10111 7.36003 2.83014 4.38196C6.13853 8.45293 11.0198 10.9278 16.2598 11.1897C15.7347 8.92648 16.4521 6.55487 18.145 4.9639C20.7695 2.4968 24.8972 2.62325 27.3643 5.24648C28.8237 4.95874 30.2224 4.42325 31.5024 3.66454C31.0159 5.17293 29.9979 6.45422 28.6379 7.26842C29.9295 7.11616 31.1914 6.77035 32.3798 6.24261C31.505 7.55358 30.403 8.69551 29.1282 9.61809Z"
                          fill="#1D9BF0"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="svg-twitter">
                          <rect
                            width="32"
                            height="26.3226"
                            fill="white"
                            transform="translate(0.489502 3)"
                          ></rect>
                        </clipPath>
                      </defs>
                    </svg>
                  </a>{" "}
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    target="_blank"
                    data-project-social-target="telegram"
                    href="https://t.me/share/url?url=https://allowlist.cryptoverse.vip/&amp;text=Check out the Cryptoverse @polkastarter IDO 🚀"
                  >
                    Telegram
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="33"
                      height="32"
                      viewBox="0 0 33 32"
                      fill="none"
                    >
                      <path
                        d="M16.4895 31.3334C24.9579 31.3334 31.8228 24.4684 31.8228 16C31.8228 7.53165 24.9579 0.666687 16.4895 0.666687C8.02113 0.666687 1.15616 7.53165 1.15616 16C1.15616 24.4684 8.02113 31.3334 16.4895 31.3334Z"
                        fill="url(#paint0_linear)"
                      ></path>
                      <path
                        d="M11.5355 17.1207L13.3562 22.156C13.3562 22.156 13.5835 22.6273 13.8268 22.6273C14.0702 22.6273 17.6935 18.8587 17.6935 18.8587L21.7215 11.0787L11.6002 15.8213L11.5355 17.1207Z"
                        fill="#C8DAEA"
                      ></path>
                      <path
                        d="M13.9475 18.412L13.5982 22.1233C13.5982 22.1233 13.4522 23.2607 14.5888 22.1233C15.7255 20.986 16.8142 20.1093 16.8142 20.1093L13.9475 18.412Z"
                        fill="#A9C6D8"
                      ></path>
                      <path
                        d="M11.5682 17.3007L7.82684 16.0814C7.82684 16.0814 7.38017 15.9001 7.5235 15.4887C7.55284 15.4034 7.61284 15.3314 7.79017 15.2067C8.6195 14.6287 23.1368 9.41072 23.1368 9.41072C23.3461 9.33722 23.5713 9.32133 23.7888 9.36472C23.8487 9.38328 23.9025 9.41737 23.9449 9.46351C23.9873 9.50964 24.0167 9.56619 24.0302 9.62739C24.0561 9.73538 24.0668 9.84643 24.0622 9.95739C24.0622 10.0534 24.0495 10.1427 24.0408 10.2827C23.9522 11.7087 21.3075 22.3567 21.3075 22.3567C21.3075 22.3567 21.1495 22.9794 20.5822 23.0007C20.4425 23.0057 20.3032 22.9824 20.1727 22.9322C20.0422 22.882 19.9232 22.806 19.8228 22.7087C18.7095 21.7507 14.8622 19.1654 14.0122 18.5967C13.9937 18.5835 13.9782 18.5665 13.9667 18.5468C13.9552 18.5272 13.948 18.5053 13.9455 18.4827C13.9335 18.4227 13.9988 18.3494 13.9988 18.3494C13.9988 18.3494 20.6975 12.3947 20.8755 11.7701C20.8895 11.7221 20.8375 11.6981 20.7675 11.7187C20.3222 11.8827 12.6095 16.7527 11.7582 17.2907C11.6965 17.3088 11.6314 17.3123 11.5682 17.3007Z"
                        fill="white"
                      ></path>
                      <defs>
                        <linearGradient
                          id="paint0_linear"
                          x1="16.4895"
                          y1="31.3334"
                          x2="16.4895"
                          y2="0.666687"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#1D93D2"></stop>
                          <stop offset="1" stop-color="#38B0E3"></stop>
                        </linearGradient>
                      </defs>
                    </svg>
                  </a>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="ps--page-wrapper ps--whitelist__information">
          <div class="ps--container ps--container--small">
            <div class="ps--project-show">
              <div class="ps--project-show__main">
                <div class="ps--project-show__logo">
                  <img
                    class="ps--table__project-img"
                    src="https://storage.googleapis.com/polkastarter-production-assets/0j5z3enclmzrrvpdji7r89jdagb3"
                  />
                </div>
                <div class="ps--project-show__information">
                  <div class="ps--project-show__status">
                    <h1 class="ps--project-show__title">Cryptoverse</h1>
                  </div>
                </div>
              </div>
            </div>
            <div class="ps--whitelist__table">
              <div class="ps--inline-table ps--inline-table--large">
                <div class="ps--inline-table__title"> Landsale Date </div>
                <div class="ps--inline-table__dotted"></div>
                <div
                  class="ps--inline-table__value"
                  data-project-partial-target="idoTime"
                >
                  March 8th 2022, 1:00 PM UTC
                </div>
              </div>
            </div>
            {/* <p class="ps--whitelist__description" data-target="applications.markdown"><p>The Cryptoverse is designed to empower, enrich and enhance all manner of users, from brands and businesses to gamers and socialites — combining the best aspects of virtual worlds so that Cryptoversians will enjoy a plethora of explosive imagination. For the first time ever, Cryptoverse LAND NFTs will be available to acquire!</p>
                    <p><strong>The Cryptoverse x Polkastarter LAND Sale Details</strong></p>
                    <ul>
                        <li><strong>Date:</strong> Tuesday, March 8, 2022</li>
                        <li><strong>LAND sizes:</strong> Epic, Giant, Large, Medium, Small, Single</li>
                        <li><strong>LAND prices:</strong> (see text below)</li>
                        <li><strong>Network:</strong> BNB Chain (formerly BSC)</li>
                    </ul>
                    <p><strong>Important Information</strong></p>
                    <ul>
                        <li>All allowlist entries will be screened and checked. The following list of possible violations will lead to automatic allowlisting disqualification:</li>
                        <li>Invalid ERC20/BEP20 addresses, Telegram/Twitter handles, and emails</li>
                        <li>Entries by bots or any form of automation</li>
                        <li>Entries with any incomplete step in the process as described above</li>
                    </ul></p> */}
            <div class="ps--whitelist__links">
              <a target="_blank" href="https://www.cryptoverse.vip/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM13.9 7H12C11.9 5.5 11.6 4.1 11.2 2.9C12.6 3.8 13.6 5.3 13.9 7ZM8 14C7.4 14 6.2 12.1 6 9H10C9.8 12.1 8.6 14 8 14ZM6 7C6.2 3.9 7.3 2 8 2C8.7 2 9.8 3.9 10 7H6ZM4.9 2.9C4.4 4.1 4.1 5.5 4 7H2.1C2.4 5.3 3.4 3.8 4.9 2.9ZM2.1 9H4C4.1 10.5 4.4 11.9 4.8 13.1C3.4 12.2 2.4 10.7 2.1 9ZM11.1 13.1C11.6 11.9 11.8 10.5 11.9 9H13.8C13.6 10.7 12.6 12.2 11.1 13.1Z"
                    fill="black"
                  ></path>
                </svg>
                Website
              </a>
              <a target="_blank" href="https://tinyurl.com/CVlitepaper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M14 0H2C1.4 0 1 0.4 1 1V15C1 15.6 1.4 16 2 16H14C14.6 16 15 15.6 15 15V1C15 0.4 14.6 0 14 0ZM8 13H4V11H8V13ZM12 9H4V7H12V9ZM12 5H4V3H12V5Z"
                    fill="black"
                  ></path>
                </svg>
                Whitepaper
              </a>
              <a target="_blank" href="https://twitter.com/CryptoverseVIP">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="13"
                  viewBox="0 0 16 13"
                  fill="none"
                >
                  <path
                    d="M16 1.5C15.4 1.8 14.8 1.9 14.1 2C14.8 1.6 15.3 1 15.5 0.2C14.9 0.6 14.2 0.8 13.4 1C12.8 0.4 11.9 0 11 0C9.3 0 7.8 1.5 7.8 3.3C7.8 3.6 7.8 3.8 7.9 4C5.2 3.9 2.7 2.6 1.1 0.6C0.8 1.1 0.7 1.6 0.7 2.3C0.7 3.4 1.3 4.4 2.2 5C1.7 5 1.2 4.8 0.7 4.6C0.7 6.2 1.8 7.5 3.3 7.8C3 7.9 2.7 7.9 2.4 7.9C2.2 7.9 2 7.9 1.8 7.8C2.2 9.1 3.4 10.1 4.9 10.1C3.8 11 2.4 11.5 0.8 11.5C0.5 11.5 0.3 11.5 0 11.5C1.5 12.4 3.2 13 5 13C11 13 14.3 8 14.3 3.7C14.3 3.6 14.3 3.4 14.3 3.3C15 2.8 15.6 2.2 16 1.5Z"
                    fill="#212121"
                  ></path>
                </svg>
                Twitter
              </a>
              <a target="_blank" href="https://t.me/CryptoverseOfficialChat">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <g class="nc-icon-wrapper" fill="#000000">
                    <path
                      d="M15.968,1.684a.338.338,0,0,0-.232-.253,1.192,1.192,0,0,0-.63.045S1.087,6.514.286,7.072c-.172.121-.23.19-.259.272-.138.4.293.573.293.573L3.933,9.094a.388.388,0,0,0,.183-.011c.822-.519,8.27-5.222,8.7-5.38.068-.02.118,0,.1.049-.172.6-6.606,6.319-6.641,6.354a.138.138,0,0,0-.049.118l-.337,3.528s-.142,1.1.956,0c.779-.779,1.527-1.425,1.9-1.738,1.242.858,2.579,1.806,3.156,2.3a1,1,0,0,0,.732.283.825.825,0,0,0,.7-.622S15.894,3.7,15.979,2.317C15.987,2.182,16,2.1,16,2A1.177,1.177,0,0,0,15.968,1.684Z"
                      fill="#000000"
                    ></path>
                  </g>
                </svg>
                Telegram
              </a>{" "}
            </div>
          </div>
        </div>
        <div class="ps--container ps--container--small">
          <form
            class="simple_form new_application"
          // id="new_application"
          // novalidate="novalidate"
          // action="/"
          // accept-charset="UTF-8"
          // method="post"
          >
            {/* <input
              type="hidden"
              name="authenticity_token"
              value="GfTDpCUE67/EpCES3BmiGYcKcw4VIj1571BXNZi5sdxv85UX4+V4QK8rf7k7+bPbSfkPwm+T6YLdGz70CiLMDw=="
            /> */}
            <div class="ps--whitelist__form ps--form">
              <section class="ps--form__section">
                <h2 class="ps--heading-sm">Join the Allowlist</h2>
                <p class="ps--form__paragraph">
                  We are excited to launch our ILO on Polkastarter. Please fill
                  out the form below and perform all tasks accordingly. If you
                  have any questions, please contact us!
                  <br />
                  <br />
                  Good Luck! 🚀
                </p>
              </section>
              <section class="ps--form__section">
                <div class="ps--form__section-title">
                  <h3 class="ps--form__title">Basic Information</h3>
                </div>
                <div class="mb-2">
                  <div class="form-floating string required application_full_name">
                    <input
                      class="form-control string required"
                      required="required"
                      aria-required="true"
                      placeholder="Full Name"
                      type="text"
                      name="application[full_name]"
                      id="application_full_name"

                      onChange={(e) => {
                        setmetaData({ ...metaData, name: e.target.value })
                      }}
                    />
                    <label class="string required" for="application_full_name">
                      Full Name <abbr title="required">*</abbr>
                    </label>
                  </div>
                </div>
                <div class="mb-2">
                  <div class="form-floating email required application_email">
                    <input
                      class="form-control string email required"
                      required="required"
                      aria-required="true"
                      placeholder="Email Address"
                      type="email"
                      name="application[email]"
                      id="application_email"
                      onChange={(e) => {
                        setmetaData({ ...metaData, email: e.target.value })
                      }}
                    />
                    <label class="email required" for="application_email">
                      Email Address <abbr title="required">*</abbr>
                    </label>
                  </div>
                </div>
                <div class="mb-2">
                  <div class="form-floating string required application_address">
                    <input
                      class="form-control string required"
                      required="required"
                      aria-required="true"
                      placeholder="Wallet Address"
                      type="text"
                      name="application[address]"
                      id="application_address"
                      value={wallet.status == "connected" ? wallet.account : ""}
                      disabled
                    // onChange={(e) => {
                    //   setmetaData({...metaData,walletAddress:e.target.value})
                    // }}
                    />
                    <label class="string required" for="application_address">
                      Wallet Address <abbr title="required">*</abbr>
                    </label>
                  </div>
                </div>
                <div class="mb-2">
                  <div class="form-floating string required application_twitter_handle">
                    <input
                      class="form-control string required"
                      required="required"
                      aria-required="true"
                      placeholder="Your Twitter Handle"
                      type="text"
                      name="application[twitter_handle]"
                      id="application_twitter_handle"
                      onChange={(e) => {
                        setmetaData({ ...metaData, twitter: e.target.value })
                      }}
                    />
                    <label
                      class="string required"
                      for="application_twitter_handle"
                    >
                      Your Twitter Handle <abbr title="required">*</abbr>
                    </label>
                  </div>
                </div>
                <div class="mb-2">
                  <div class="form-floating string required application_telegram_handle">
                    <input
                      class="form-control string required"
                      required="required"
                      aria-required="true"
                      placeholder="Your Telegram Handle"
                      type="text"
                      name="application[telegram_handle]"
                      id="application_telegram_handle"
                      onChange={(e) => {
                        setmetaData({ ...metaData, telegram: e.target.value })
                      }}
                    />
                    <label
                      class="string required"
                      for="application_telegram_handle"
                    >
                      Your Telegram Handle <abbr title="required">*</abbr>
                    </label>
                  </div>
                </div>
              </section>
              <section class="ps--form__section">
                <div class="ps--form__section-title">
                  <h3 class="ps--form__title">Social Media</h3>
                  <h4 class="ps--form__subtitle">
                    Please complete the social tasks below. They’re optional,
                    but provide increased chances of getting allowlisted.
                  </h4>
                </div>
                <div class="ps--whitelist__social">
                  <div class="ps--whitelist__social__item">
                    <div class="ps--whitelist__social__info">
                      <div class="ps--whitelist__social__logo twitter">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="13"
                          viewBox="0 0 16 13"
                          fill="none"
                        >
                          <path
                            d="M16 1.5C15.4 1.8 14.8 1.9 14.1 2C14.8 1.6 15.3 1 15.5 0.2C14.9 0.6 14.2 0.8 13.4 1C12.8 0.4 11.9 0 11 0C9.3 0 7.8 1.5 7.8 3.3C7.8 3.6 7.8 3.8 7.9 4C5.2 3.9 2.7 2.6 1.1 0.6C0.8 1.1 0.7 1.6 0.7 2.3C0.7 3.4 1.3 4.4 2.2 5C1.7 5 1.2 4.8 0.7 4.6C0.7 6.2 1.8 7.5 3.3 7.8C3 7.9 2.7 7.9 2.4 7.9C2.2 7.9 2 7.9 1.8 7.8C2.2 9.1 3.4 10.1 4.9 10.1C3.8 11 2.4 11.5 0.8 11.5C0.5 11.5 0.3 11.5 0 11.5C1.5 12.4 3.2 13 5 13C11 13 14.3 8 14.3 3.7C14.3 3.6 14.3 3.4 14.3 3.3C15 2.8 15.6 2.2 16 1.5Z"
                            fill="#212121"
                          ></path>
                        </svg>
                      </div>
                      <div class="ps--whitelist__social__wrapper">
                        <div class="ps--whitelist__social__title">Follow</div>
                        <div class="ps--whitelist__social__subtitle">
                          Cryptoverse Twitter
                        </div>
                      </div>
                    </div>
                    <div
                      class="ps--whitelist__social__actions"
                      id="cryptoverse_followProjectTwitter"
                    >
                      <a
                        class="btn btn-sm btn-light"
                        target="_blank"
                        href="https://twitter.com/CryptoverseVIP"
                      >
                        Go to Feed
                      </a>{" "}
                      <a
                        class="btn btn-sm btn-outline-secondary"
                        data-action="click->applications#toggleSocialStatus"
                        href="javascript:void(0)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM7 11.4L3.6 8L5 6.6L7 8.6L11 4.6L12.4 6L7 11.4Z"
                            fill="black"
                          ></path>
                        </svg>
                        <span>I did this</span>
                      </a>{" "}
                    </div>
                  </div>
                  <div class="ps--whitelist__social__item">
                    <div class="ps--whitelist__social__info">
                      <div class="ps--whitelist__social__logo twitter">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="13"
                          viewBox="0 0 16 13"
                          fill="none"
                        >
                          <path
                            d="M16 1.5C15.4 1.8 14.8 1.9 14.1 2C14.8 1.6 15.3 1 15.5 0.2C14.9 0.6 14.2 0.8 13.4 1C12.8 0.4 11.9 0 11 0C9.3 0 7.8 1.5 7.8 3.3C7.8 3.6 7.8 3.8 7.9 4C5.2 3.9 2.7 2.6 1.1 0.6C0.8 1.1 0.7 1.6 0.7 2.3C0.7 3.4 1.3 4.4 2.2 5C1.7 5 1.2 4.8 0.7 4.6C0.7 6.2 1.8 7.5 3.3 7.8C3 7.9 2.7 7.9 2.4 7.9C2.2 7.9 2 7.9 1.8 7.8C2.2 9.1 3.4 10.1 4.9 10.1C3.8 11 2.4 11.5 0.8 11.5C0.5 11.5 0.3 11.5 0 11.5C1.5 12.4 3.2 13 5 13C11 13 14.3 8 14.3 3.7C14.3 3.6 14.3 3.4 14.3 3.3C15 2.8 15.6 2.2 16 1.5Z"
                            fill="#212121"
                          ></path>
                        </svg>
                      </div>
                      <div class="ps--whitelist__social__wrapper">
                        <div class="ps--whitelist__social__title">Retweet</div>
                        <div class="ps--whitelist__social__subtitle">
                          Cryptoverse Announcement
                        </div>
                      </div>
                    </div>
                    <div
                      class="ps--whitelist__social__actions"
                      id="cryptoverse_retweet"
                    >
                      <a
                        class="btn btn-sm btn-light"
                        target="_blank"
                        href="https://twitter.com/CryptoverseVIP/status/1495805245477343233"
                      >
                        Go to Tweet
                      </a>{" "}
                      <a
                        class="btn btn-sm btn-outline-secondary"
                        data-action="click->applications#toggleSocialStatus"
                        href="javascript:void(0)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM7 11.4L3.6 8L5 6.6L7 8.6L11 4.6L12.4 6L7 11.4Z"
                            fill="black"
                          ></path>
                        </svg>
                        <span>I did this</span>
                      </a>{" "}
                    </div>
                  </div>
                  <div class="ps--whitelist__social__item">
                    <div class="ps--whitelist__social__info">
                      <div class="ps--whitelist__social__logo discord">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                        >
                          <g class="nc-icon-wrapper" fill="#000000">
                            <path
                              d="M6.552,6.712a.891.891,0,0,0,0,1.776A.852.852,0,0,0,7.368,7.6.847.847,0,0,0,6.552,6.712Zm2.92,0a.891.891,0,1,0,.816.888A.852.852,0,0,0,9.472,6.712Z"
                              data-color="color-2"
                            ></path>{" "}
                            <path
                              d="M13.36,0H2.64A1.644,1.644,0,0,0,1,1.648V12.464a1.644,1.644,0,0,0,1.64,1.648h9.072l-.424-1.48,1.024.952.968.9L15,16V1.648A1.644,1.644,0,0,0,13.36,0ZM10.272,10.448S9.984,10.1,9.744,9.8a2.524,2.524,0,0,0,1.448-.952,4.578,4.578,0,0,1-.92.472,5.265,5.265,0,0,1-1.16.344A5.6,5.6,0,0,1,7.04,9.656a6.716,6.716,0,0,1-1.176-.344,4.683,4.683,0,0,1-.912-.472,2.488,2.488,0,0,0,1.4.944c-.24.3-.536.664-.536.664a2.9,2.9,0,0,1-2.44-1.216A10.713,10.713,0,0,1,4.528,4.568a3.956,3.956,0,0,1,2.248-.84l.08.1a5.4,5.4,0,0,0-2.1,1.048s.176-.1.472-.232a6.008,6.008,0,0,1,1.816-.5.788.788,0,0,1,.136-.016A6.769,6.769,0,0,1,8.792,4.1a6.521,6.521,0,0,1,2.408.768A5.324,5.324,0,0,0,9.208,3.856l.112-.128a3.956,3.956,0,0,1,2.248.84A10.713,10.713,0,0,1,12.72,9.232,2.924,2.924,0,0,1,10.272,10.448Z"
                              fill="#000000"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="ps--whitelist__social__wrapper">
                        <div class="ps--whitelist__social__title">Join</div>
                        <div class="ps--whitelist__social__subtitle">
                          Cryptoverse Discord channel
                        </div>
                      </div>
                    </div>
                    <div
                      class="ps--whitelist__social__actions"
                      id="cryptoverse_joinDiscordServer"
                    >
                      <a
                        class="btn btn-sm btn-light"
                        target="_blank"
                        href="https://discord.gg/4bH5pGGMkN"
                      >
                        Join Server
                      </a>{" "}
                      <a
                        class="btn btn-sm btn-outline-secondary"
                        data-action="click->applications#toggleSocialStatus"
                        href="javascript:void(0)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM7 11.4L3.6 8L5 6.6L7 8.6L11 4.6L12.4 6L7 11.4Z"
                            fill="black"
                          ></path>
                        </svg>
                        <span>I did this</span>
                      </a>{" "}
                    </div>
                  </div>
                  <div class="ps--whitelist__social__item">
                    <div class="ps--whitelist__social__info">
                      <div class="ps--whitelist__social__logo telegram">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                        >
                          <g class="nc-icon-wrapper" fill="#000000">
                            <path
                              d="M15.968,1.684a.338.338,0,0,0-.232-.253,1.192,1.192,0,0,0-.63.045S1.087,6.514.286,7.072c-.172.121-.23.19-.259.272-.138.4.293.573.293.573L3.933,9.094a.388.388,0,0,0,.183-.011c.822-.519,8.27-5.222,8.7-5.38.068-.02.118,0,.1.049-.172.6-6.606,6.319-6.641,6.354a.138.138,0,0,0-.049.118l-.337,3.528s-.142,1.1.956,0c.779-.779,1.527-1.425,1.9-1.738,1.242.858,2.579,1.806,3.156,2.3a1,1,0,0,0,.732.283.825.825,0,0,0,.7-.622S15.894,3.7,15.979,2.317C15.987,2.182,16,2.1,16,2A1.177,1.177,0,0,0,15.968,1.684Z"
                              fill="#000000"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="ps--whitelist__social__wrapper">
                        <div class="ps--whitelist__social__title">Join</div>
                        <div class="ps--whitelist__social__subtitle">
                          Cryptoverse Channel
                        </div>
                      </div>
                    </div>
                    <div
                      class="ps--whitelist__social__actions"
                      id="cryptoverse_joinProjectTelegramChannel"
                    >
                      <a
                        class="btn btn-sm btn-light"
                        target="_blank"
                        href="https://t.me/CryptoverseOfficialChat"
                      >
                        Join Channel
                      </a>{" "}
                      <a
                        class="btn btn-sm btn-outline-secondary"
                        data-action="click->applications#toggleSocialStatus"
                        href="javascript:void(0)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM7 11.4L3.6 8L5 6.6L7 8.6L11 4.6L12.4 6L7 11.4Z"
                            fill="black"
                          ></path>
                        </svg>
                        <span>I did this</span>
                      </a>{" "}
                    </div>
                  </div>
                  <div class="ps--whitelist__social__item">
                    <div class="ps--whitelist__social__info">
                      <div class="ps--whitelist__social__logo medium">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M2.25627 4.35257C2.27498 4.15715 2.20449 3.964 2.06646 3.83248L0.660406 2.04183V1.77435H5.0262L8.40072 9.59818L11.3675 1.77435H15.5294V2.04183L14.3272 3.26036C14.2236 3.34387 14.1721 3.48114 14.1937 3.617V12.5702C14.1721 12.7061 14.2236 12.8433 14.3272 12.9269L15.5013 14.1453V14.4128H9.59586V14.1453L10.8121 12.8971C10.9316 12.7708 10.9316 12.7336 10.9316 12.5405V5.30362L7.55006 14.3831H7.09309L3.15615 5.30362V11.3888C3.12332 11.6447 3.2037 11.9022 3.37409 12.0872L4.9559 14.1157V14.3831H0.470589V14.1157L2.0524 12.0872C2.22154 11.9019 2.29723 11.6426 2.25627 11.3888V4.35257Z"
                            fill="black"
                          ></path>
                        </svg>
                      </div>
                      <div class="ps--whitelist__social__wrapper">
                        <div class="ps--whitelist__social__title">Follow</div>
                        <div class="ps--whitelist__social__subtitle">
                          Cryptoverse Medium Page
                        </div>
                      </div>
                    </div>
                    <div
                      class="ps--whitelist__social__actions"
                      id="cryptoverse_followProjectMediumPage"
                    >
                      <a
                        class="btn btn-sm btn-light"
                        target="_blank"
                        href="https://cryptoversevip.medium.com/"
                      >
                        Follow Page
                      </a>{" "}
                      <a
                        class="btn btn-sm btn-outline-secondary"
                        data-action="click->applications#toggleSocialStatus"
                        href="javascript:void(0)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM7 11.4L3.6 8L5 6.6L7 8.6L11 4.6L12.4 6L7 11.4Z"
                            fill="black"
                          ></path>
                        </svg>
                        <span>I did this</span>
                      </a>{" "}
                    </div>
                  </div>
                  <div class="ps--whitelist__social__item">
                    <div class="ps--whitelist__social__info">
                      <div class="ps--whitelist__social__logo twitter">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="13"
                          viewBox="0 0 16 13"
                          fill="none"
                        >
                          <path
                            d="M16 1.5C15.4 1.8 14.8 1.9 14.1 2C14.8 1.6 15.3 1 15.5 0.2C14.9 0.6 14.2 0.8 13.4 1C12.8 0.4 11.9 0 11 0C9.3 0 7.8 1.5 7.8 3.3C7.8 3.6 7.8 3.8 7.9 4C5.2 3.9 2.7 2.6 1.1 0.6C0.8 1.1 0.7 1.6 0.7 2.3C0.7 3.4 1.3 4.4 2.2 5C1.7 5 1.2 4.8 0.7 4.6C0.7 6.2 1.8 7.5 3.3 7.8C3 7.9 2.7 7.9 2.4 7.9C2.2 7.9 2 7.9 1.8 7.8C2.2 9.1 3.4 10.1 4.9 10.1C3.8 11 2.4 11.5 0.8 11.5C0.5 11.5 0.3 11.5 0 11.5C1.5 12.4 3.2 13 5 13C11 13 14.3 8 14.3 3.7C14.3 3.6 14.3 3.4 14.3 3.3C15 2.8 15.6 2.2 16 1.5Z"
                            fill="#212121"
                          ></path>
                        </svg>
                      </div>
                      <div class="ps--whitelist__social__wrapper">
                        <div class="ps--whitelist__social__title">Follow</div>
                        <div class="ps--whitelist__social__subtitle">
                          Polkastarter Gaming
                        </div>
                      </div>
                    </div>
                    <div
                      class="ps--whitelist__social__actions"
                      id="cryptoverse_followPolkastarterGamingTwitter"
                    >
                      <a
                        class="btn btn-sm btn-light"
                        target="_blank"
                        href="https://twitter.com/PolkastarterGG"
                      >
                        Follow Account
                      </a>{" "}
                      <a
                        class="btn btn-sm btn-outline-secondary"
                        data-action="click->applications#toggleSocialStatus"
                        href="javascript:void(0)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM7 11.4L3.6 8L5 6.6L7 8.6L11 4.6L12.4 6L7 11.4Z"
                            fill="black"
                          ></path>
                        </svg>
                        <span>I did this</span>
                      </a>{" "}
                    </div>
                  </div>
                  <div class="ps--whitelist__social__item">
                    <div class="ps--whitelist__social__info">
                      <div class="ps--whitelist__social__logo discord">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                        >
                          <g class="nc-icon-wrapper" fill="#000000">
                            <path
                              d="M6.552,6.712a.891.891,0,0,0,0,1.776A.852.852,0,0,0,7.368,7.6.847.847,0,0,0,6.552,6.712Zm2.92,0a.891.891,0,1,0,.816.888A.852.852,0,0,0,9.472,6.712Z"
                              data-color="color-2"
                            ></path>{" "}
                            <path
                              d="M13.36,0H2.64A1.644,1.644,0,0,0,1,1.648V12.464a1.644,1.644,0,0,0,1.64,1.648h9.072l-.424-1.48,1.024.952.968.9L15,16V1.648A1.644,1.644,0,0,0,13.36,0ZM10.272,10.448S9.984,10.1,9.744,9.8a2.524,2.524,0,0,0,1.448-.952,4.578,4.578,0,0,1-.92.472,5.265,5.265,0,0,1-1.16.344A5.6,5.6,0,0,1,7.04,9.656a6.716,6.716,0,0,1-1.176-.344,4.683,4.683,0,0,1-.912-.472,2.488,2.488,0,0,0,1.4.944c-.24.3-.536.664-.536.664a2.9,2.9,0,0,1-2.44-1.216A10.713,10.713,0,0,1,4.528,4.568a3.956,3.956,0,0,1,2.248-.84l.08.1a5.4,5.4,0,0,0-2.1,1.048s.176-.1.472-.232a6.008,6.008,0,0,1,1.816-.5.788.788,0,0,1,.136-.016A6.769,6.769,0,0,1,8.792,4.1a6.521,6.521,0,0,1,2.408.768A5.324,5.324,0,0,0,9.208,3.856l.112-.128a3.956,3.956,0,0,1,2.248.84A10.713,10.713,0,0,1,12.72,9.232,2.924,2.924,0,0,1,10.272,10.448Z"
                              fill="#000000"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="ps--whitelist__social__wrapper">
                        <div class="ps--whitelist__social__title">Join</div>
                        <div class="ps--whitelist__social__subtitle">
                          Polkastarter Gaming Guild Discord
                        </div>
                      </div>
                    </div>
                    <div
                      class="ps--whitelist__social__actions"
                      id="cryptoverse_joinDiscordServer"
                    >
                      <a
                        class="btn btn-sm btn-light"
                        target="_blank"
                        href="https://discord.com/invite/pgg"
                      >
                        Join Server
                      </a>{" "}
                      <a
                        class="btn btn-sm btn-outline-secondary"
                        data-action="click->applications#toggleSocialStatus"
                        href="javascript:void(0)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM7 11.4L3.6 8L5 6.6L7 8.6L11 4.6L12.4 6L7 11.4Z"
                            fill="black"
                          ></path>
                        </svg>
                        <span>I did this</span>
                      </a>{" "}
                    </div>
                  </div>
                </div>
              </section>
              <div class="form-floating hidden application_form form-group-valid">
                <input
                  class="form-control hidden"
                  type="hidden"
                  value="97"
                  name="application[form_id]"
                  id="application_form_id"
                />
              </div>
              <div class="form-floating hidden application_project form-group-valid">
                <input
                  class="form-control hidden"
                  type="hidden"
                  value="93"
                  name="application[project_id]"
                  id="application_project_id"
                />
              </div>
              <section class="ps--form__section">
                <div class="ps--form__section-title">
                  <h3 class="ps--form__title">About Cryptoverse</h3>
                </div>
                <div class="form-floating hidden application_answers_question form-group-valid">
                  <input
                    class="form-control hidden"
                    type="hidden"
                    value="165"
                    name="application[answers_attributes][0][question_id]"
                    id="application_answers_attributes_0_question_id"
                  />
                </div>
                <div class="ps--form__question">
                  <h4 class="ps--form__subtitle mb-2">
                    What is The Cryptoverse?
                  </h4>
                  <fieldset class="form-group radio_buttons required application_answers_choices">
                    <input
                      type="hidden"
                      name="application[answers_attributes][0][choice_ids][]"
                      value=""
                    />
                    <div class="form-check">
                      <input
                        class="form-check-input radio_buttons required"
                        name="application[answers_attributes][0][choice_ids][]"
                        required="required"
                        aria-required="true"
                        type="radio"
                        value="320"
                        id="application_answers_attributes_0_choice_ids_320"
                      />
                      <label
                        class="form-check-label collection_radio_buttons"
                        for="application_answers_attributes_0_choice_ids_320"
                      >
                        An innovative DeFi protocol.
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input radio_buttons required"
                        name="application[answers_attributes][0][choice_ids][]"
                        required="required"
                        aria-required="true"
                        type="radio"
                        value="321"
                        id="application_answers_attributes_0_choice_ids_321"
                      />
                      <label
                        class="form-check-label collection_radio_buttons"
                        for="application_answers_attributes_0_choice_ids_321"
                      >
                        A Play-To-Earn blockchain game.
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input radio_buttons required"
                        name="application[answers_attributes][0][choice_ids][]"
                        required="required"
                        aria-required="true"
                        type="radio"
                        value="322"
                        id="application_answers_attributes_0_choice_ids_322"
                      />
                      <label
                        class="form-check-label collection_radio_buttons"
                        for="application_answers_attributes_0_choice_ids_322"
                      >
                        A 3D virtual world underpinned by blockchain technology.
                      </label>
                    </div>
                  </fieldset>
                </div>
                <div class="form-floating hidden application_answers_question form-group-valid">
                  <input
                    class="form-control hidden"
                    type="hidden"
                    value="167"
                    name="application[answers_attributes][1][question_id]"
                    id="application_answers_attributes_1_question_id"
                  />
                </div>
                <div class="ps--form__question">
                  <h4 class="ps--form__subtitle mb-2">
                    Which token will power The Cryptoverse?
                  </h4>
                  <fieldset class="form-group radio_buttons required application_answers_choices">
                    <input
                      type="hidden"
                      name="application[answers_attributes][1][choice_ids][]"
                      value=""
                    />
                    <div class="form-check">
                      <input
                        class="form-check-input radio_buttons required"
                        name="application[answers_attributes][1][choice_ids][]"
                        required="required"
                        aria-required="true"
                        type="radio"
                        value="326"
                        id="application_answers_attributes_1_choice_ids_326"
                      />
                      <label
                        class="form-check-label collection_radio_buttons"
                        for="application_answers_attributes_1_choice_ids_326"
                      >
                        $CGG
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input radio_buttons required"
                        name="application[answers_attributes][1][choice_ids][]"
                        required="required"
                        aria-required="true"
                        type="radio"
                        value="327"
                        id="application_answers_attributes_1_choice_ids_327"
                      />
                      <label
                        class="form-check-label collection_radio_buttons"
                        for="application_answers_attributes_1_choice_ids_327"
                      >
                        $BOOST
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input radio_buttons required"
                        name="application[answers_attributes][1][choice_ids][]"
                        required="required"
                        aria-required="true"
                        type="radio"
                        value="328"
                        id="application_answers_attributes_1_choice_ids_328"
                      />
                      <label
                        class="form-check-label collection_radio_buttons"
                        for="application_answers_attributes_1_choice_ids_328"
                      >
                        $CRYPTO
                      </label>
                    </div>
                  </fieldset>
                </div>
                <div class="form-floating hidden application_answers_question form-group-valid">
                  <input
                    class="form-control hidden"
                    type="hidden"
                    value="166"
                    name="application[answers_attributes][2][question_id]"
                    id="application_answers_attributes_2_question_id"
                  />
                </div>
                <div class="ps--form__question">
                  <h4 class="ps--form__subtitle mb-2">
                    The Cryptoverse is underpinned by which ecosystem?
                  </h4>
                  <fieldset class="form-group radio_buttons required application_answers_choices">
                    <input
                      type="hidden"
                      name="application[answers_attributes][2][choice_ids][]"
                      value=""
                    />
                    <div class="form-check">
                      <input
                        class="form-check-input radio_buttons required"
                        name="application[answers_attributes][2][choice_ids][]"
                        required="required"
                        aria-required="true"
                        type="radio"
                        value="323"
                        id="application_answers_attributes_2_choice_ids_323"
                      />
                      <label
                        class="form-check-label collection_radio_buttons"
                        for="application_answers_attributes_2_choice_ids_323"
                      >
                        Sandbox
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input radio_buttons required"
                        name="application[answers_attributes][2][choice_ids][]"
                        required="required"
                        aria-required="true"
                        type="radio"
                        value="324"
                        id="application_answers_attributes_2_choice_ids_324"
                      />
                      <label
                        class="form-check-label collection_radio_buttons"
                        for="application_answers_attributes_2_choice_ids_324"
                      >
                        ChainGuardians
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input radio_buttons required"
                        name="application[answers_attributes][2][choice_ids][]"
                        required="required"
                        aria-required="true"
                        type="radio"
                        value="325"
                        id="application_answers_attributes_2_choice_ids_325"
                      />
                      <label
                        class="form-check-label collection_radio_buttons"
                        for="application_answers_attributes_2_choice_ids_325"
                      >
                        Decentraland
                      </label>
                    </div>
                  </fieldset>
                </div>
                <h3 class="ps--form__title">
                  To apply for the land sale please specify how much of each
                  estate you’re interested in buying.
                </h3>
                <div class="form-floating hidden application_answers_question form-group-valid">
                  <input
                    class="form-control hidden"
                    type="hidden"
                    value="159"
                    name="application[answers_attributes][3][question_id]"
                    id="application_answers_attributes_3_question_id"
                  />
                </div>
                <div
                  class="ps--form__question"
                  data-question-value="85000"
                  data-question-title="Epic"
                >
                  <h4 class="ps--form__title mb-2">Epic</h4>
                  <h4 class="ps--form__subtitle mb-2">
                    Each Epic estate costs $85,000 and contains 490 parcels of
                    land
                  </h4>
                  <div class="form-floating integer required application_answers_body">
                    <input
                      class="form-control numeric integer required"
                      data-action="change->applications#sumQuestionsNumber"
                      required="required"
                      aria-required="true"
                      placeholder=""
                      type="number"
                      step="1"
                      name="application[answers_attributes][3][body]"
                      id="application_answers_attributes_3_body"
                    />
                    <label
                      class="integer required"
                      for="application_answers_attributes_3_body"
                    >
                      Choose your amount (total available slots 7){" "}
                      <abbr title="required">*</abbr>
                    </label>
                  </div>
                </div>
                <div class="form-floating hidden application_answers_question form-group-valid">
                  <input
                    class="form-control hidden"
                    type="hidden"
                    value="160"
                    name="application[answers_attributes][4][question_id]"
                    id="application_answers_attributes_4_question_id"
                  />
                </div>
                <div
                  class="ps--form__question"
                  data-question-value="47500"
                  data-question-title="Giant"
                >
                  <h4 class="ps--form__title mb-2">Giant</h4>
                  <h4 class="ps--form__subtitle mb-2">
                    Each Giant estate costs $47,500 and contains 274 parcels of
                    land
                  </h4>
                  <div class="form-floating integer required application_answers_body">
                    <input
                      class="form-control numeric integer required"
                      data-action="change->applications#sumQuestionsNumber"
                      required="required"
                      aria-required="true"
                      placeholder=""
                      type="number"
                      step="1"
                      name="application[answers_attributes][4][body]"
                      id="application_answers_attributes_4_body"
                    />
                    <label
                      class="integer required"
                      for="application_answers_attributes_4_body"
                    >
                      Choose your amount (total available slots 25){" "}
                      <abbr title="required">*</abbr>
                    </label>
                  </div>
                </div>
                <div class="form-floating hidden application_answers_question form-group-valid">
                  <input
                    class="form-control hidden"
                    type="hidden"
                    value="161"
                    name="application[answers_attributes][5][question_id]"
                    id="application_answers_attributes_5_question_id"
                  />
                </div>
                <div
                  class="ps--form__question"
                  data-question-value="31000"
                  data-question-title="Large"
                >
                  <h4 class="ps--form__title mb-2">Large</h4>
                  <h4 class="ps--form__subtitle mb-2">
                    Each Large estate costs $31,000 and contains 179 parcels of
                    land
                  </h4>
                  <div class="form-floating integer required application_answers_body">
                    <input
                      class="form-control numeric integer required"
                      data-action="change->applications#sumQuestionsNumber"
                      required="required"
                      aria-required="true"
                      placeholder=""
                      type="number"
                      step="1"
                      name="application[answers_attributes][5][body]"
                      id="application_answers_attributes_5_body"
                    />
                    <label
                      class="integer required"
                      for="application_answers_attributes_5_body"
                    >
                      Choose your amount (total available slots 58){" "}
                      <abbr title="required">*</abbr>
                    </label>
                  </div>
                </div>
                <div class="form-floating hidden application_answers_question form-group-valid">
                  <input
                    class="form-control hidden"
                    type="hidden"
                    value="162"
                    name="application[answers_attributes][6][question_id]"
                    id="application_answers_attributes_6_question_id"
                  />
                </div>
                <div
                  class="ps--form__question"
                  data-question-value="18500"
                  data-question-title="Medium"
                >
                  <h4 class="ps--form__title mb-2">Medium</h4>
                  <h4 class="ps--form__subtitle mb-2">
                    Each Medium estate costs $18,500 and contains 107 parcels of
                    land
                  </h4>
                  <div class="form-floating integer required application_answers_body">
                    <input
                      class="form-control numeric integer required"
                      data-action="change->applications#sumQuestionsNumber"
                      required="required"
                      aria-required="true"
                      placeholder=""
                      type="number"
                      step="1"
                      name="application[answers_attributes][6][body]"
                      id="application_answers_attributes_6_body"
                    />
                    <label
                      class="integer required"
                      for="application_answers_attributes_6_body"
                    >
                      Choose your amount (total available slots 162){" "}
                      <abbr title="required">*</abbr>
                    </label>
                  </div>
                </div>
                <div class="form-floating hidden application_answers_question form-group-valid">
                  <input
                    class="form-control hidden"
                    type="hidden"
                    value="163"
                    name="application[answers_attributes][7][question_id]"
                    id="application_answers_attributes_7_question_id"
                  />
                </div>
                <div
                  class="ps--form__question"
                  data-question-value="5200"
                  data-question-title="Small"
                >
                  <h4 class="ps--form__title mb-2">Small</h4>
                  <h4 class="ps--form__subtitle mb-2">
                    Each Small estate costs $5,200 and contains 30 parcels of
                    land
                  </h4>
                  <div class="form-floating integer required application_answers_body">
                    <input
                      class="form-control numeric integer required"
                      data-action="change->applications#sumQuestionsNumber"
                      required="required"
                      aria-required="true"
                      placeholder=""
                      type="number"
                      step="1"
                      name="application[answers_attributes][7][body]"
                      id="application_answers_attributes_7_body"
                    />
                    <label
                      class="integer required"
                      for="application_answers_attributes_7_body"
                    >
                      Choose your amount (total available slots 505){" "}
                      <abbr title="required">*</abbr>
                    </label>
                  </div>
                </div>
                <div class="form-floating hidden application_answers_question form-group-valid">
                  <input
                    class="form-control hidden"
                    type="hidden"
                    value="164"
                    name="application[answers_attributes][8][question_id]"
                    id="application_answers_attributes_8_question_id"
                  />
                </div>
                <div
                  class="ps--form__question"
                  data-question-value="180"
                  data-question-title="Single"
                >
                  <h4 class="ps--form__title mb-2">Single</h4>
                  <h4 class="ps--form__subtitle mb-2">
                    Each Single estate costs $180 and contains 1 parcel of land
                  </h4>
                  <div class="form-floating integer required application_answers_body">
                    <input
                      class="form-control numeric integer required"
                      data-action="change->applications#sumQuestionsNumber"
                      required="required"
                      aria-required="true"
                      placeholder=""
                      type="number"
                      step="1"
                      name="application[answers_attributes][8][body]"
                      id="application_answers_attributes_8_body"
                    />
                    <label
                      class="integer required"
                      for="application_answers_attributes_8_body"
                    >
                      Choose your amount (total available slots 15000){" "}
                      <abbr title="required">*</abbr>
                    </label>
                  </div>
                </div>
              </section>

              <section class="ps--form__section d-none" data-total-section="">
                <h3 class="ps--form__title mb-2">You're applying for</h3>
                <p data-total-description=""></p>
                <p>
                  $<span data-total-price=""></span>
                </p>
              </section>
              <section class="ps--form__section">
                <div class="ps--form__question mb-3">
                  <h4 class="ps--form__subtitle mb-2">
                    Do you agree with the
                    <a
                      target="_blank"
                      href="https://polkastarter.com/terms#:~:text=you%20are%20(i)%20aged%2018,watchlists%2C%20and%20(v)%20that"
                    >
                      Terms and Conditions
                    </a>
                    ?
                  </h4>
                  <fieldset class="form-group boolean optional application_terms_of_service_accepted">
                    <div class="form-check">
                      <input
                        name="application[terms_of_service_accepted]"
                        type="hidden"
                        value="0"
                      />
                      <input
                        class="form-check-input boolean optional"
                        type="checkbox"
                        value="1"
                        name="application[terms_of_service_accepted]"
                        id="application_terms_of_service_accepted"
                      />
                      <label
                        class="form-check-label boolean optional"
                        for="application_terms_of_service_accepted"
                      >
                        I accept the terms of service
                      </label>
                    </div>
                  </fieldset>
                </div>
                <div class="ps--form__question">
                  <h4 class="ps--form__subtitle mb-2">
                    Do you want to receive updates from Cryptoverse
                  </h4>
                  <fieldset class="form-group boolean optional application_subscribe_to_newsletter_accepted">
                    <div class="form-check">
                      <input
                        name="application[subscribe_to_newsletter_accepted]"
                        type="hidden"
                        value="0"
                      />
                      <input
                        class="form-check-input boolean optional"
                        type="checkbox"
                        value="1"
                        name="application[subscribe_to_newsletter_accepted]"
                        id="application_subscribe_to_newsletter_accepted"
                      />
                      <label
                        class="form-check-label boolean optional"
                        for="application_subscribe_to_newsletter_accepted"
                      >
                        Yes, I agree to receive updates from Cryptoverse in the
                        future.
                      </label>
                    </div>
                  </fieldset>
                </div>
              </section>
              <script
                src="https://www.recaptcha.net/recaptcha/api.js"
                async=""
                defer=""
              ></script>
              <div
                data-sitekey="6LcsBIEcAAAAAAcd4W4yb2nJtrGtrdDrdM286EwC"
                class="g-recaptcha "
              >
                <div style={{ width: "304px", height: "78px" }}>
                  <div>
                    <iframe
                      title="reCAPTCHA"
                      src="https://www.recaptcha.net/recaptcha/api2/anchor?ar=1&amp;k=6LcsBIEcAAAAAAcd4W4yb2nJtrGtrdDrdM286EwC&amp;co=aHR0cHM6Ly9hbGxvd2xpc3QuY3J5cHRvdmVyc2UudmlwOjQ0Mw..&amp;hl=en&amp;v=_exWVY_hlNJJl2Abm8pI9i1L&amp;size=normal&amp;cb=986u75n7mudk"
                      role="presentation"
                      name="a-7q6kj6q81a0v"
                      scrolling="no"
                      sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                      width="304"
                      height="78"
                      frameborder="0"
                    ></iframe>
                  </div>
                  <textarea
                    id="g-recaptcha-response"
                    name="g-recaptcha-response"
                    class="g-recaptcha-response"
                    style={{
                      width: "250px",
                      height: "40px",
                      border: "1px solid rgb(193, 193, 193)",
                      margin: "10px 25px",
                      padding: "0px",
                      resize: "none",
                      display: "none",
                    }}
                  ></textarea>
                </div>
                <iframe style={{ display: "none" }}></iframe>
              </div>
              {/* <noscript>
                                        <div>
                                            <div style="width: 302px; height: 422px; position: relative;">
                                                <div style="width: 302px; height: 422px; position: absolute;">
                                                    <iframe
                                                        src="https://www.recaptcha.net/recaptcha/api/fallback?k=6LcsBIEcAAAAAAcd4W4yb2nJtrGtrdDrdM286EwC"
                                                        name="ReCAPTCHA"
                                                        style="width: 302px; height: 422px; border-style: none; border: 0; overflow: hidden;">
                                                    </iframe>
                                                </div>
                                            </div>
                                            <div style="width: 300px; height: 60px; border-style: none;
                bottom: 12px; left: 25px; margin: 0px; padding: 0px; right: 25px;
                background: #f9f9f9; border: 1px solid #c1c1c1; border-radius: 3px;">
                                                <textarea id="g-recaptcha-response" name="g-recaptcha-response"
                                                    class="g-recaptcha-response"
                                                    style="width: 250px; height: 40px; border: 1px solid #c1c1c1;
                  margin: 10px 25px; padding: 0px; resize: none;">
                                                </textarea>
                                            </div>
                                        </div>
                                    </noscript> */}
              {/* <input
                type="submit"
                name="commit"
                value="Submit your application"
                class="btn btn-100 btn-light"
                data-confirm="Are you sure? After you submit the application you will not be able to change it."
                data-disable-with="Submit your application"
              /> */}

            </div>
          </form>{" "}
          <button onClick={() => {
            handleSubmit("hii")
          }}>
            Submit your application
          </button>
        </div>
      </div>
    </div>
  );
}

export default WhitelistFrom;
