import { ACCOUNT_UPDATE, CONTRACT_LOADED, DATA_LOADED } from "../constants/action-types";



const initialState = {
  account: null,
};

function rootReducer(state = initialState, action) {
  console.log(action, "action")

  switch (action.type) {
    case ACCOUNT_UPDATE:
      return Object.assign({}, state, {
        account: action.payload
      });
  }

  return state;
};



export default rootReducer;