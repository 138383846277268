import { BrowserRouter, Routes, Route } from "react-router-dom";
import BigNumber from "bignumber.js";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Project from "./pages/Projects";
import ProjectDetail from "./pages/ProjectDetail";
import Dashboard from "./pages/Dashboard";
import WhiteList from "./pages/Whitelist";
import Terms from "./pages/TermsCondition/Terms";
import Privacy from "./pages/PrivacyPolicy/Privacy";
import Allocations from "./pages/AllocationPage/Allocation";
import Applyido from "./pages/Applyido";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

function App() {
  return (
    <div className="App">
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Project />} />
          <Route path="/project" element={<ProjectDetail />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/whitelist" element={<WhiteList />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/applyido" element={<Applyido />} />
          <Route path="/allocations" element={<Allocations />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
