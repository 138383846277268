import React from "react";
import styled from "styled-components";

const Page = styled.div`
  min-height: 100vh;
  display: grid;
  place-items: center;

  @media only screen and (max-width: 768px) {
    display: block;
  }
`;
const T = styled.p`
  font-weight: 500;
  font-size: 1.1rem;
  vertical-align: middle;
  //   margin-bottom: 2rem;
  text-align: center;
  color: rgba(125, 130, 139, 1);

  word-wrap: break-word;
  @media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 1rem;
  }
`;
const Width = styled.div`
  width: 1300px;
  display: flex;
  padding: 3rem 0 0 0;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 1400px) {
    width: 90%;
  }
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
    margin: 6rem 0 0 0;
  }

  @media only screen and (max-width: 510px) {
    width: 98%;
    margin: 4rem 0 0 0;
  }
`;

const Left = styled.div`
  width: 45%;

  @media only screen and (max-width: 998px) {
    width: 85%;
  }

  @media only screen and (max-width: 768px) {
    width: 85%;
  }
`;

const Right = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;

  @media only screen and (max-width: 1100px) {
    width: 50%;
    margin: 3rem 0 0 0;
    height: auto;
  }
  @media only screen and (max-width: 998px) {
    width: 65%;
  }

  @media only screen and (max-width: 768px) {
    width: 25rem;
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;

const Ht = styled.h1`
  color: #fff;
  font-size: 3rem;
  //   margin-bottom: 2rem;
  text-align: center;

  @media only screen and (max-width: 1300px) {
    font-size: 4rem;
  }

  @media only screen and (max-width: 1300px) {
    font-size: 3rem;
  }

  @media only screen and (max-width: 1100px) {
    font-size: 2.5rem;
  }

  @media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 1.2rem;
    font-weight: bold;
  }
`;

function Applyido() {
  return (
    <div
      className="ps--container"
      style={{ paddingBottom: "20px", paddingTop: "20px" }}
    >
      <div
        style={{
          padding: " 0 0 0",
        }}
        className="ps--container"
      >
        <Ht>The first IDO Platform based from Indonesia</Ht>
        <T>
          Welcome to T-Launchpad. Select projects are vetted by the team with
          investors to enables the Launch of innovative technology. Applicants
          should submit a form of application to join. Launchpad review team
          will be in touch with you far further notifications.
        </T>
        {/* <T></T>
        <T></T> */}
        <T>Thank you for your interest.</T>
        <a href="/projects" style={{ margin: "auto", textDecoration: "none" }}>
          <button
            className="btn btn-success w-10  mt-3"
            //   onClick={() => {
            //     handleReedemToken(e.id);
            //   }}
            style={{ color: "#fff" }}
          >
            Start Application
          </button>
        </a>
        <div className="" style={{}}></div>
        <Ht>The IDO Journey</Ht>
      </div>
      <div className="ps--card-grid">
        <div className="ps--project-card__blur">
          <div
            className="ps--project-card ps--hover"
            style={{ height: "200px", minHeight: "200px", padding: "10px" }}
          >
            <Ht>Step 1</Ht>
            <T style={{ textAlign: "left", margin: "20px" }}>
              Submit your application by filling in the form
            </T>
          </div>
        </div>
        <div className="ps--project-card__blur">
          <div
            className="ps--project-card ps--hover"
            style={{ height: "200px", minHeight: "200px", padding: "10px" }}
          >
            <Ht>Step 2</Ht>
            <T style={{ textAlign: "left", margin: "20px" }}>
              Program will be reviewed each project carefully before being
              shortlisted by IDO committees
            </T>
          </div>
        </div>
        <div className="ps--project-card__blur">
          <div
            className="ps--project-card ps--hover"
            style={{ height: "200px", minHeight: "200px", padding: "10px" }}
          >
            <Ht>Step 3</Ht>
            <T style={{ textAlign: "left", margin: "20px" }}>
              Once you're in, you will undergo commercial terms negotiation
            </T>
          </div>
        </div>
      </div>
      <div className="ps--card-grid">
        <div className="ps--project-card__blur">
          <div
            className="ps--project-card ps--hover"
            style={{ height: "200px", minHeight: "200px", padding: "10px" }}
          >
            <Ht>Step 4</Ht>
            <T style={{ textAlign: "left", margin: "20px" }}>
              Once you're in, you will undergo commercial terms negotiation
            </T>
          </div>
        </div>
        <div className="ps--project-card__blur">
          <div
            className="ps--project-card ps--hover"
            style={{ height: "200px", minHeight: "200px", padding: "10px" }}
          >
            <Ht>Step 5</Ht>
            <T style={{ textAlign: "left", margin: "20px" }}>
              Boarding on campaign, marketing, and PR coordination with
              stakeholders
            </T>
          </div>
        </div>
        <div className="ps--project-card__blur">
          <div
            className="ps--project-card ps--hover"
            style={{ height: "200px", minHeight: "200px", padding: "10px" }}
          >
            <Ht>Step 6</Ht>
            <T style={{ textAlign: "left", margin: "20px" }}></T>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Applyido;
