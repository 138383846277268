import React from 'react'
import styled from 'styled-components'
import Hbg from './headerbg.png';

const Sec = styled.section`
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    // justify-content: center;
   
  
    flex-direction: column;
    @media only screen and (max-width: 998px) {
        min-height: 100vh;
        width: 100%;
        padding: 2rem 0;
        flex-direction: column;
        background-size: auto 100%;
      }
`;

const Width = styled.div`
      width: 1100px;
 
   display: flex;
   flex-direction: column;

    align-items: center;
    justify-content: center;
     padding: 0 0 5rem 0;
    @media only screen and (max-width: 1300px) {
        width: 98%;
    }

    @media only screen and (max-width: 510px) {
      padding: 0 1rem;
      width: 100%;
  }
  
`;

const StyleCard = styled.div`
      width: 1600px;
      display: grid;
    
      grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr));
 
 
      @media only screen and (max-width: 1600px) {
        width: 98%;
     
      }
      @media only screen and (max-width: 510px) {
    
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
      }
`

const Heading = styled.div`
    color: rgba(2, 169, 92, 1);
    font-size: 1.6rem;

   
    background-size: 100% 100%;
    background-repeat: no-repeat;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.2rem;
        font-weight: bold;
      }
`;

const Background = styled.div`
    background-color: rgba(0, 29, 35, 1);
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
      padding: 1rem 0 0 0;
      position: relative;
`;

const Dashboard = styled.div`
    background-color: rgba(2, 169, 92, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1300px;
    padding: 2rem 0;
    border-radius: 1.2rem;
 
    margin: 3rem 0 -6rem 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

    @media only screen and (max-width: 1350px) {
      width: 98%;
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
    }

    @media only screen and (max-width: 1093px) {
      margin: 3rem 0 -12rem 0;
    }
`;

const H = styled.p`
   
    font-weight: 500;
    font-size: 2rem;
    margin: 0 0 0.2rem 0;
    @media only screen and (max-width: 768px) {
        
      }
`;

const SmallH = styled.p`
   
    font-weight: 500;
    font-size: 1.7rem;
    margin: 0 0 0.2rem 0;
    @media only screen and (max-width: 768px) {
        
      }
`;

const T = styled.p`
      font-size: 1.2rem;
      font-weight: 300;
      line-height: 1.5;

    @media only screen and (max-width: 768px) {
        
      }
`;

const Header = styled.div`
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid   rgba(50, 58, 73, 1);
      background-image: url(${Hbg});
      height: 15rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
`
const Row = styled.div`
      display: flex;
      margin: 1rem 0 0 2rem;
`

const Terms = () => {

    return (
        <Sec >
          <Width>
            <Header>
              <div>
                <H>Terms & Condition</H>
                <T>Last updated: <span style={{fontWeight:'400'}}>March 3, 2022</span> </T>
              </div>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="16" cy="16" r="16" fill="#222833"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11 15V13C11 11.6739 11.5268 10.4021 12.4645 9.46447C13.4021 8.52678 14.6739 8 16 8C17.3261 8 18.5979 8.52678 19.5355 9.46447C20.4732 10.4021 21 11.6739 21 13V15C21.5304 15 22.0391 15.2107 22.4142 15.5858C22.7893 15.9609 23 16.4696 23 17V22C23 22.5304 22.7893 23.0391 22.4142 23.4142C22.0391 23.7893 21.5304 24 21 24H11C10.4696 24 9.96086 23.7893 9.58579 23.4142C9.21071 23.0391 9 22.5304 9 22V17C9 16.4696 9.21071 15.9609 9.58579 15.5858C9.96086 15.2107 10.4696 15 11 15ZM19 13V15H13V13C13 12.2044 13.3161 11.4413 13.8787 10.8787C14.4413 10.3161 15.2044 10 16 10C16.7956 10 17.5587 10.3161 18.1213 10.8787C18.6839 11.4413 19 12.2044 19 13Z" fill="#62CE9A"/>
              </svg>
              
            </Header>
            <T style={{margin:'2rem 0 0 0'}}>
                This end user agreement (the "Agreement") should be read by you (the "User" or
                "you") in its entirety prior to your use of Polkastarter's service or products. Be
                aware that this Agreement constitutes a legally binding agreement between you
                and Polkastarter (referred to herein as "Polkastarter", "us" or "we") which owns and
                operates the website on the Internet and the Service at www.Polkastarter.com (the
                "Service"). You consent to adhering to all the terms set forth in this Agreement
                when/if you are provided with an “I Agree” button and clicking on it.
            </T>

            <div style={{width:'100%',margin:'2rem 0 0 0'}}>
                <SmallH style={{textAlign:'left'}}>1. Grant of License</SmallH>
                <Row>
                  <T>a.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    Polkastarter grants the User a non-exclusive, personal, non-transferable right to use the Service
                    on your personal computer or other device that accesses the Internet in order to access and use
                    the services available and described on the Polkastarter.com website (the website and all its
                    functionality together being the "Service"), subject to the terms and conditions contained herein.
                  </T>
                </Row>

                <Row>
                  <T>b.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    The Service is not for use by (i) minors and individuals under the age of 18 years, (ii) individuals
                    who can be considered minors or under the age of majority adulthood in their specific
                    jurisdiction(s) (iii) individuals accessing or using the Service from any jurisdiction in which it is
                    illegal to do so. Polkastarter does not have the ability to verify the legality of the Service in every
                    jurisdiction, therefore it is entirely up to the User to determine whether or not their use of the
                    Service is lawful.
                  </T>
                </Row>

                <Row>
                  <T>c.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    Polkastarter and its licensors are the sole holders of all rights in and to the Service and code,
                    technology, organization and structure, architecture, including copyright, trade secrets,
                    intellectual property and other rights. You may not: (a) copy, create derivative works, distribute,
                    publish, reverse engineer, decompile, disassemble, modify, or translate the Polkastarter website
                    or the Service; or (b) use the Service that in any way is prohibited by applicable laws or
                    regulations (each of the above herein defined as an "Unauthorized Use").
                  </T>
                </Row>

                <Row>
                  <T>d.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    Polkastarter reserves any and all rights implied or otherwise, which are not expressly granted to
                    the User hereunder and retain all rights, title and interest in and to the Service. You agree that
                    you will be solely liable for any damage, costs or expenses arising out of or in connection with
                    the commission by you of any Unauthorized Use. You shall notify Polkastarter immediately upon
                    becoming aware of the commission by any person of any Unauthorized Use and shall provide
                    Polkastarter with reasonable assistance with any investigations it conducts in light of the
                    information provided by you in this respect.
                  </T>
                </Row>

                <Row>
                  <T>e.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    The term "Polkastarter", its domain names and any other trade marks, or service marks used by
                    Polkastarter as part of the Service (the "Trade Marks"), are solely owned by Polkastarter. In
                    addition, all content on the website, including, but not limited to, the images, pictures, graphics,
                    photographs, animations, videos, music, audio and text (the "Site Content") belongs to
                    Polkastarter and is protected by copyright and/or other intellectual property or other rights. You
                    hereby acknowledge that by using the Service, you obtain no rights in the Site Content and/or
                    the Trade Marks, or any part thereof. Under no circumstances may you use the Site Content and
                    or the Trade Marks without Polkastarter's prior written consent. Additionally, you agree not to do
                    anything that will harm or potentially harm the rights, including the intellectual property rights of
                    Polkastarter.
                  </T>
                </Row>
            </div>

            <div style={{width:'100%',margin:'2rem 0 0 0'}}>
                <SmallH style={{textAlign:'left'}}>2. No Warranties</SmallH>
                <Row>
                  <T>a.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    Polkastarter disclaims any and all warranties, expressed or implied, in connection with the
                    service which is provided to you "as is" and we provide you with no warranty or representation
                    whatsoever regarding its quality, fitness for purpose, completeness or accuracy.
                  </T>
                </Row>

                <Row>
                  <T>b.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    Polkastarter disclaims any and all warranties, expressed or implied, in connection with the
                    service which is provided to you "as is" and we provide you with no warranty or representation
                    whatsoever regarding its quality, fitness for purpose, completeness or accuracy.
                  </T>
                </Row>

            </div>

            <div style={{width:'100%',margin:'2rem 0 0 0'}}>
                <SmallH style={{textAlign:'left'}}>3. Authority / Terms of Service</SmallH>
                <Row>
                
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    You agree to the rules of the Service provided and described on the Polkastarter.com website.
                    Polkastarter retains all authority over the issuing, maintenance, and closing of the Service. The
                    decision of Polkastarter's management, concerning any use of the Service, or dispute resolution,
                    is final and shall not be open to review or appeal.
                  </T>
                </Row>

            </div>

            <div style={{width:'100%',margin:'2rem 0 0 0'}}>
                <SmallH style={{textAlign:'left'}}>4. Your Representations and Warranties</SmallH>
                <Row>
                  <T>a.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    There is a risk of losing cryptocurrency & other funds of value when using the Service and
                    Polkastarter has no responsibility to you for any such loss;
                  </T>
                </Row>

                <Row>
                  <T>b.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                  Your use of the Service is at your sole option, discretion and risk;
                  </T>
                </Row>

                <Row>
                  <T>c.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    You are solely responsible for any applicable taxes which may be payable on cryptocurrency
                    traded or transacted by you through your using the Service;
                  </T>
                </Row>

                <Row>
                  <T>d.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    The telecommunications networks, blockchain networks (such as Polkadot and Ethereum) and
                    Internet access services required for you to access and use the Service are entirely beyond the
                    control of Polkastarter and Polkastarter shall have no liability whatsoever for any outages,
                    slowness, capacity constraints or other deficiencies affecting the same; and
                  </T>
                </Row>

                <Row>
                  <T>e.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    You are (i) aged 18 or over, (ii) you are of the age of majority in your jurisdiction, (iii) you are
                    accessing the Service from a jurisdiction in which it is legal to do so, (iv) you are not a Politically
                    Exposed Person (PEP) nor are you on any U.S. or EU Sanctions lists or terrorism finance
                    watchlists, and (v) that you will inform Polkastarter immediately if you enter onto one of these
                    lists or change your residence to a prohibited jurisdiction.
                  </T>
                </Row>

                <Row>
                  <T>f.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    You warrant that should you discover a bug or error that may lead to an exploit of the Service or
                    other loss of funds from Polkastarter, you have the responsibility to notify Polkastarter at
                    Support@Polkastarter.com in a timely manner. Furthermore, any attempt by you to use such a
                    discovered exploit or bug for illicit financial gain is illegal and strictly prohibited under this
                    Agreement. Polkastarter reserves the right to prosecute to the fullest extent of the law anyone
                    who exploits or attempts to exploit the Service in an unlawful manner. Such exploits or bugs
                    should be disclosed in a responsible manner and in strict confidence with
                    support@Polkastarter.com and no other entity.
                  </T>
                </Row>
            </div>

            <div style={{width:'100%',margin:'2rem 0 0 0'}}>
                <SmallH style={{textAlign:'left'}}>5. Prohibited Uses</SmallH>
                <T>Prior to your use of the Service and on an ongoing basis you represent, warrant, covenant and agree that:</T>
                <Row>
                  <T>a.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    PERSONAL USE. The Service is intended solely for the User’s personal use. The User is only
                    allowed to use the Service for personal use, and may not create multiple accounts, including for
                    the purpose of collusion and/or abuse of service.
                  </T>
                </Row>

                <Row>
                  <T>b.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    EXPLOITS & BUGS. The Service is provided as is, and any exploitation of the Service or errors in
                    program logic and/or code (bugs) constitutes a Prohibited Use and a material breach of this
                    Agreement. Any funds the user may misappropriate in this manner pursuant to this clause shall
                    be considered void and the immediate property of Polkastarter, without limit.
                  </T>
                </Row>

                <Row>
                  <T>c.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    JURISDICTIONS. Persons located in or residents of the United States, North Korea, Iran,
                    Venezuela or any other jurisdiction in which it is prohibited from using the Service (the
                    ”Prohibited Jurisdictions”) are not permitted to make use of the Service. For the avoidance of
                    doubt, the foregoing restrictions on Service from Prohibited Jurisdictions applies equally to
                    residents and citizens of other nations while located in a Prohibited Jurisdiction. Any attempt to
                    circumvent the restrictions on usage by any persons located in a Prohibited Jurisdiction or
                    Restricted Jurisdiction, is a breach of this Agreement. An attempt at circumvention includes, but is not limited to, manipulating the information used by Polkastarter to identify your location and providing Polkastarter with false or misleading information regarding your location or place of residence.
                  </T>
                </Row>

                <Row>
                  <T></T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    Any and all monies (including cryptocurrencies) of a person located in a Prohibited Jurisdiction on
                    the Service are VOID, and can be confiscated or may be returned to the person at Polkastarter's
                    sole discretion.
                  </T>
                </Row>

            </div>

            <div style={{width:'100%',margin:'2rem 0 0 0'}}>
                <SmallH style={{textAlign:'left'}}>6. Know your Customer (“KYC”) and Anti-Money </SmallH>
                
                <Row>
                  
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    Polkastarter is a permissionless and fully decentralised platform for token sales, swaps, and
                    decentralized exchange. As a software development company, Polkastarter has no role in
                    enforcing KYC by default, however we do provide such tools for fundraising entities using
                    Polkastarter to enforce on their users, if the entities choose to do so. The use of KYC/AML tools
                    on Polkastarter by fundraising entities using the Service is completely at the discretion of said
                    entities.
                  </T>
                </Row>

                <Row>
                 
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    Although Polkastarter makes no warranty as to the merit, legality or juridical nature of any token
                    (including whether or not it is considered a security or financial instrument under any applicable
                    Securities Laws), token sale or fundraiser on top of Polkastarter, we nonetheless understand the
                    need of some token projects to require KYC/AML on their token sale participants.
                  </T>
                </Row>

                <Row>
                  
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    Therefore, and at the sole behest of fundraising entities and/or competent regulatory authorities:
                  </T>
                </Row>

                <Row>
              
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    Polkastarter reserves the right, at any time, to ask for any KYC documentation it deems
                    necessary to determine the identity and location of a User. Polkastarter reserves the right to
                    restrict service and payment until identity is sufficiently determined.
                  </T>
                </Row>


                <Row>
              
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    Polkastarter further reserves the right to share submitted KYC information and documentation to
                    3rd parties to verify the authenticity of submitted information, and the end user (you) agree to
                    this by using the Service.
                  </T>
                </Row>

                <Row style={{flexDirection: 'column',marginLeft:'2.5rem'}}>
                  <SmallH style={{fontSize:'1.4rem'}}>AML & SANCTIONS COMPLIANCE.</SmallH>
                  <T>
                  Polkastarter expressly prohibits and rejects the use of the Service for any form of illicit activity,
                  including money laundering, terrorist financing or trade sanctions violations, consistent with
                  various jurisdictions’ laws, regulations and norms. To that end, the Service is not offered to
                  individuals or entities on any Politically Exposed Persons (PEP) lists, or subject to any United
                  States, European Union, or other global sanctions or watch lists. By using the Service, end users
                  represent and warrant that they are not on any such lists.
                  </T>
                </Row>


            </div>

            <div style={{width:'100%',margin:'2rem 0 0 0'}}>
                <SmallH style={{textAlign:'left'}}>7. Breach</SmallH>
                <Row>
                  <T>a.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    Without prejudice to any other rights, if a User breaches in whole or in part any provision
                    contained herein, Polkastarter reserves the right to take such action as it sees fit, including
                    terminating this Agreement or any other agreement in place with the User and/or taking legal
                    action against such User.
                  </T>
                </Row>

                <Row>
                  <T>b.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                    You agree to fully indemnify, defend and hold harmless Polkastarter and its shareholders,
                    directors, agents and employees from and against all claims, demands, liabilities, damages,
                    losses, costs and expenses, including legal fees and any other charges whatsoever, howsoever
                    caused, that may arise as a result of: (i) your breach of this Agreement, in whole or in part; (ii)
                    violation by you of any law or any third party rights; and (iii) use by you of the Service.
                  </T>
                </Row>

            </div>

            <div style={{width:'100%',margin:'2rem 0 0 0'}}>
                <SmallH style={{textAlign:'left'}}>8. Limitations and Liability</SmallH>
                <Row>
                  <T>a.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                  Under no circumstances, including negligence, shall Polkastarter be liable for any special,
                  incidental, direct, indirect or consequential damages whatsoever (including, without limitation,
                  damages for loss of business profits, business interruption, loss of business information, or any
                  other pecuniary loss) arising out of the use (or misuse) of the Service even if Polkastarter had
                  prior knowledge of the possibility of such damages.
                  </T>
                </Row>

                <Row>
                  <T>b.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                  Nothing in this Agreement shall exclude or limit Polkastarter's liability for death or personal injury
                  resulting from its negligence.
                  </T>
                </Row>

            </div>

            <div style={{width:'100%',margin:'2rem 0 0 0'}}>
                <SmallH style={{textAlign:'left'}}>9. Disputes</SmallH>
                <Row>
                  <T></T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                  If a User wishes to make a complaint, please contact Polkastarter's customer service team at
Support@Polkastarter.com. Should any dispute not be resolved to your satisfaction you may
pursue remedies in the governing law jurisdiction set forth below.
                  </T>
                </Row>

            </div>

            <div style={{width:'100%',margin:'2rem 0 0 0'}}>
                <SmallH style={{textAlign:'left'}}>10. Amendment</SmallH>
                <Row>
                  <T></T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                  Polkastarter reserves the right to update or modify this Agreement or any part thereof at any
time or otherwise change the Service without notice and you will be bound by such amended
Agreement upon posting. Therefore, we encourage you to check the terms and conditions
contained in the version of the Agreement in force at such time. Your continued use of the
Service shall be deemed to attest to your agreement to any amendments to the Agreement.
                  </T>
                </Row>

            </div>

            <div style={{width:'100%',margin:'2rem 0 0 0'}}>
                <SmallH style={{textAlign:'left'}}>11. Governing Law</SmallH>
                <Row>
                  <T></T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                  The Agreement and any matters relating hereto shall be governed by, and construed in
accordance with, the laws of the British Virgin Islands. You irrevocably agree that, subject as
provided below, the courts of the British Virgin Islands shall have exclusive jurisdiction in relation
to any claim, dispute or difference concerning the Agreement and any matter arising therefrom
and irrevocably waive any right that it may have to object to an action being brought in those
courts, or to claim that the action has been brought in an inconvenient forum, or that those
courts do not have jurisdiction. Nothing in this clause shall limit the right of Polkastarter to take
proceedings against you in any other court of competent jurisdiction, nor shall the taking of
proceedings in any one or more jurisdictions preclude the taking of proceedings in any other
jurisdictions, whether concurrently or not, to the extent permitted by the law of such other
jurisdiction.
                  </T>
                </Row>

            </div>

            <div style={{width:'100%',margin:'2rem 0 0 0'}}>
                <SmallH style={{textAlign:'left'}}>12. Severability</SmallH>
                <Row>
                  <T></T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                  If a provision of this Agreement is or becomes illegal, invalid or unenforceable in any jurisdiction,
that shall not affect the validity or enforceability in that jurisdiction of any other provision here of
or the validity or enforceability in other jurisdictions of that or any other provision hereof.
                  </T>
                </Row>

            </div>

            <div style={{width:'100%',margin:'2rem 0 0 0'}}>
                <SmallH style={{textAlign:'left'}}>13. Assignment</SmallH>
                <Row>
                  <T></T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                  Polkastarter reserves the right to assign this agreement, in whole or in part, at any time without
notice. The User may not assign any of his/her rights or obligations under this Agreement.
                  </T>
                </Row>

            </div>

            <div style={{width:'100%',margin:'2rem 0 0 0'}}>
                <SmallH style={{textAlign:'left'}}>14. Miscellaneous</SmallH>
                <Row>
                  <T>a.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                  No waiver by Polkastarter of any breach of any provision of this Agreement (including the failure
of Polkastarter to require strict and literal performance of or compliance with any provision of
this Agreement) shall in any way be construed as a waiver of any subsequent breach of such
provision or of any breach of any other provision of this Agreement.
                  </T>
                </Row>

                <Row>
                  <T>b.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                  Nothing in this Agreement shall create or confer any rights or other benefits in favour of any third
parties not party to this Agreement.
                  </T>
                </Row>

                <Row>
                  <T>c.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                  Nothing in this Agreement shall create or confer any rights or other benefits in favour of any third
parties not party to this Agreement.
                  </T>
                </Row>

                <Row>
                  <T>d.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                  Polkastarter may assign, transfer, charge, sub-license, or deal in any other manner with this
Agreement, or sub-contract any of its rights and obligations under this Agreement, to any other
party.
                  </T>
                </Row>

                <Row>
                  <T>e.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                  This Agreement constitutes the entire understanding and agreement between you and
Polkastarter regarding the Service and supersedes any prior agreement, understanding, or
arrangement between you and Polkastarter.
                  </T>
                </Row>

            </div>

            <div style={{width:'100%',margin:'2rem 0 0 0'}}>
                <SmallH style={{textAlign:'left'}}>15. Cryptocurrency</SmallH>
                <Row>
                  <T>.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                  CRYPTOCURRENCY VALUES CAN FLUCTUATE GREATLY IN VALUE DEPENDING ON MARKET CONDITIONS.
                  </T>
                </Row>

                <Row>
                  <T>.</T>
                  <T style={{margin:'0 0 0 0.7rem'}}>
                  THE USER WARRANTS THAT IT IS AWARE OF THE VOLATILE NATURE OF CRYPTOCURRENCIES, AND HOLDS POLKASTARTER HARMLESS FOR ANY LOSS OR DAMAGES ARISING FROM SUCH VOLATILITY
                  </T>
                </Row>

            </div>

          </Width>
        </Sec>
    )
}

export default Terms
