import React from "react";
import styled from "styled-components";
import looper from "./LooperGroup.png";
import NoAllocation from "./components/NoAllocation";
import Loading from "./components/Loading";

const Sec = styled.section`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: center;

  background-image: url(${looper});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  flex-direction: column;
  background-position: center center;
  background-attachment: fixed;
  @media only screen and (max-width: 998px) {
    min-height: 100vh;
    width: 100%;
    padding: 2rem 0;
    flex-direction: column;
    background-size: auto 100%;
  }
`;

const Width = styled.div`
  width: 1100px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  padding: 0 0 5rem 0;
  @media only screen and (max-width: 1300px) {
    width: 98%;
  }

  @media only screen and (max-width: 510px) {
    padding: 0 1rem;
    width: 100%;
  }
`;

const StyleCard = styled.div`
  width: 1600px;
  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr));

  @media only screen and (max-width: 1600px) {
    width: 98%;
  }
  @media only screen and (max-width: 510px) {
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
`;

const Heading = styled.div`
  color: rgba(2, 169, 92, 1);
  font-size: 1.6rem;

  background-size: 100% 100%;
  background-repeat: no-repeat;
  @media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 1.2rem;
    font-weight: bold;
  }
`;

const Background = styled.div`
  background-color: rgba(0, 29, 35, 1);
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0 0 0;
  position: relative;
`;

const Dashboard = styled.div`
  background-color: rgba(2, 169, 92, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1300px;
  padding: 2rem 0;
  border-radius: 1.2rem;

  margin: 3rem 0 -6rem 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

  @media only screen and (max-width: 1350px) {
    width: 98%;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
  }

  @media only screen and (max-width: 1093px) {
    margin: 3rem 0 -12rem 0;
  }
`;

const H = styled.p`
  font-weight: 500;
  font-size: 2rem;
  margin: 0 0 0.2rem 0;
  @media only screen and (max-width: 768px) {
  }
`;

const SmallH = styled.p`
  font-weight: 500;
  font-size: 1.7rem;
  margin: 0 0 0.2rem 0;
  @media only screen and (max-width: 768px) {
  }
`;

const T = styled.p`
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.5;

  @media only screen and (max-width: 768px) {
  }
`;

const Header = styled.div`
  width: 100%;
  text-align: center;

  background-color: rgba(40, 51, 66, 1);
  padding: 1.5rem 0;
`;
const Row = styled.div`
  display: flex;
  margin: 1rem 0 0 2rem;
`;

const Allocations = () => {
  return (
    <Sec>
      <Header>
        It can take up to 2 minutes after joining an IDO for the Allocations to
        appear.
      </Header>
      <Width>
        <NoAllocation />
        {/* <Loading /> */}
      </Width>
    </Sec>
  );
};

export default Allocations;
