import BigNumber from "bignumber.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import Modal from "react-modal/lib/components/Modal";
import { useWallet } from "use-wallet";
import Web3 from "web3";
import idoABI from "../../ABI/ido.json";
import { toWei } from "../../utils";

function Sidebar({ ido }) {
  const [investModal, setinvestModal] = useState(false);
  const [inputToken, setinputToken] = useState(0);

  const [mypurchases, setmypurchases] = useState([]);

  const [priceWillPay, setpriceWillPay] = useState("");
  const [individualMinimumAmount, setIndividualMinimumAmount] = useState(0);
  const [individualMaximumAmount, setIndividualMaximumAmount] = useState(0);
  const wallet = useWallet();

  const handleApply = async (token) => {
    // setinvestModal(true);
    // let tokenInWei = toWei(token);
    const web3 = new Web3(Web3.givenProvider);
    const contractInstance = new web3.eth.Contract(idoABI, ido.contractAddress);
    console.log(contractInstance);

    const handleInput = await checknumber(token);
    // (token * 10 ** 18).toString()
    console.log(handleInput);
    const price = await contractInstance.methods
      .cost(handleInput.toString())
      .call();

    const _individualMinimumAmount = await contractInstance.methods
      .individualMinimumAmount()
      .call();

    setIndividualMinimumAmount(_individualMinimumAmount);

    const _individualMaximumAmount = await contractInstance.methods
      .individualMaximumAmount()
      .call();

    setIndividualMaximumAmount(_individualMaximumAmount);

    setpriceWillPay(price);

    console.log(price, "price", token);

    if (Number(handleInput) > _individualMaximumAmount) {
      alert("Amount is bigger than maximum amount");
      return;
    }

    if (Number(handleInput) < _individualMinimumAmount) {
      alert("Amount is bigger than minimum amount");
      return;
    }
    const tx = await contractInstance.methods
      .swap(handleInput.toString())
      .send({
        from: wallet.account,
        value: price,
      });
    console.log(tx);
  };

  const handleReedemToken = async (id) => {
    const web3 = new Web3(Web3.givenProvider);
    const contractInstance = new web3.eth.Contract(idoABI, ido.contractAddress);
    console.log(contractInstance);
    // getPurchase
    const tx = await contractInstance.methods.redeemTokens(id).send({
      from: wallet.account,
    });

    console.log(tx);
  };

  const handleMypurchase = async () => {
    const web3 = new Web3(Web3.givenProvider);

    const contractInstance = new web3.eth.Contract(idoABI, ido.contractAddress);

    const array = [];

    if (ido.myPurcahses.length > 0) {
      for (let i in ido.myPurcahses) {
        // console.log(ido.myPurcahses[i].toNumber());
        const data = await contractInstance.methods
          .getPurchase(ido.myPurcahses[i].toNumber())
          .call();
        console.log(data);
        data.id = ido.myPurcahses[i].toNumber();
        array.push(data);
      }
      setmypurchases(array);
      console.log(array);
    }
  };

  const Completionist = () => <span></span>;

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <div className="ps--countdown" id="js--countdown">
          <div className="ps--countdown__item">
            <div className="ps--countdown__value days">{days}</div>
            <div className="ps--countdown__title">Days</div>
          </div>
          <span className="ps--countdown__divider">:</span>
          <div className="ps--countdown__item">
            <div className="ps--countdown__value hours">{hours}</div>
            <span className="ps--countdown__title">Hours</span>
          </div>
          <span className="ps--countdown__divider">:</span>
          <div className="ps--countdown__item">
            <div className="ps--countdown__value minutes">{minutes}</div>
            <div className="ps--countdown__title">Minutes</div>
          </div>
          <span className="ps--countdown__divider">:</span>
          <div className="ps--countdown__item">
            <div className="ps--countdown__value seconds">{seconds}</div>
            <div className="ps--countdown__title">Seconds</div>
          </div>
        </div>
      );
      // <span >{hours}:{minutes}:{seconds}</span>;
    }
  };

  const whitelist = async () => {
    const web3 = new Web3(Web3.givenProvider);
    const contractInstance = new web3.eth.Contract(idoABI, ido.contractAddress);
    const owner = await contractInstance.methods.owner().call();
    console.log(owner, contractInstance, "contract");
    const tx = await contractInstance.methods.add([wallet.account]).send({
      from: wallet.account,
    });
    console.log(tx);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "9999999",
    },
  };

  useEffect(() => {
    handleMypurchase();

    return () => {};
  }, [mypurchases.length]);

  const checknumber = (number) => {
    const no = handleAmount(number);
    return no;
  };

  const insertZero = (value, length) => {
    const intialValue = "000000000000000000";
    // const length = value.length;
    const finalstring = intialValue.slice(length);
    console.log(finalstring, value.concat(finalstring), "final");
    return value.concat(finalstring);
  };

  const handleAmount = async (amount) => {
    if (amount.indexOf(".") != -1) {
      console.log("in if");
      const int = amount.split(".");
      console.log("int", int);
      const value = insertZero(int[1], int[1].length);
      const tens = int[0].concat("000000000000000000");
      const total = parseInt(tens) + parseInt(value);
      console.log(total);
      const web3 = new Web3(Web3.givenProvider);
      const contractInstance = new web3.eth.Contract(
        idoABI,
        ido.contractAddress
      );
      console.log(contractInstance);
      // (token * 10 ** 18).toString()

      const price = await contractInstance.methods.cost(total).call();
      setpriceWillPay(price);
      return total.toString();
      // return int.concat("0000000000000000000");
    } else {
      const value = amount.concat("000000000000000000");
      console.log(value);
      const web3 = new Web3(Web3.givenProvider);
      const contractInstance = new web3.eth.Contract(
        idoABI,
        ido.contractAddress
      );
      console.log(contractInstance);
      // (token * 10 ** 18).toString()

      const price = await contractInstance.methods.cost(value).call();
      setpriceWillPay(price);
      return value;
    }
  };

  return (
    <div>
      <div
        style={{ backgroundColor: "rgba(34, 40, 51, 1)" }}
        className="ps--project__sidebar"
        data-controller="project-sidebar"
        data-id="45"
        data-pool-is-whitelisted="false"
      >
        <div className="ps--project__sidebar__wrapper">
          <div className="ps--project__sidebar__goal">
            <h5 className="ps--project__sidebar__goal__title">
              Fundraise Goal
            </h5>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h1>{(ido.totalRaisedCost / 10 ** 18).toLocaleString()}</h1>
              {/* <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#C4C4C4" />
              </svg> */}
              <img src="/images/bnb.png" width="40px" height="40px" />
            </div>
            {/* <h3 className="ps--project__sidebar__goal__raise" data-project-partial-target="totalRaise">
                        <svg xmlns="http://www.w3.org/2000/svg" className="ps--spinner" width="65px"
                            height="65px" viewBox="0 0 66 66">
                            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33"
                                cy="33" r="30"></circle>
                        </svg>
                    </h3> */}
            {/* <h4 className="ps--project__sidebar__goal__description mt-3 d-none"
                        data-project-sidebar-target="mainDescription">
                        <svg xmlns="http://www.w3.org/2000/svg" className="ps--spinner" width="65px"
                            height="65px" viewBox="0 0 66 66">
                            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33"
                                cy="33" r="30"></circle>
                        </svg>
                    </h4> */}
            {/* <h4 className="ps--project__sidebar__goal__description mt-3 d-none"
                        data-project-sidebar-target="secondaryDescription">
                        <svg xmlns="http://www.w3.org/2000/svg" className="ps--spinner" width="65px"
                            height="65px" viewBox="0 0 66 66">
                            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33"
                                cy="33" r="30"></circle>
                        </svg>
                    </h4> */}
          </div>
          {/* <div className="ps--project__sidebar__progress "
                        data-project-sidebar-target="progressHolder">
                        <div className="progress">
                            <div className="progress-bar ps--progress-animation ps--success" role="progressbar"
                                style={{ width: `${(ido.tokensForSale - ido.tokenLeft) / 100}%` }}
                                data-project-sidebar-target="progressBar">
                                <span data-project-sidebar-target="progressValue">0%</span>
                            </div>
                        </div>
                    </div> */}
          <div
            className="ps--project__sidebar__inline-table d-block"
            data-project-sidebar-target="metricsHolder"
          >
            <div className="ps--inline-table">
              <div
                style={{ color: "rgba(230, 230, 230, 1)", fontWeight: "300" }}
                className="ps--inline-table__title"
              >
                Allocation
              </div>
              <div className="ps--inline-table__dotted"></div>
              {/* <div className="ps--inline-table__value" data-project-partial-target="allocation">
                            <svg xmlns="http://www.w3.org/2000/svg" className="ps--spinner" width="65px"
                                height="65px" viewBox="0 0 66 66">
                                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round"
                                    cx="33" cy="33" r="30"></circle>
                            </svg>
                        </div> */}
              <div
                style={{ color: "rgba(244, 243, 243, 1)", fontWeight: "300" }}
                className="ps--inline-table__value"
              >
                {(ido.minimumPersonalAllocation / 10 ** 18).toLocaleString()}{" "}
                <img src="/images/bnb.png" width="20px" height="20px" />
              </div>
            </div>
            <div className="ps--inline-table">
              <div
                style={{ color: "rgba(230, 230, 230, 1)", fontWeight: "300" }}
                className="ps--inline-table__title"
              >
                Price per token
              </div>
              <div className="ps--inline-table__dotted"></div>
              {!ido.tradeValue && (
                <div
                  style={{ color: "rgba(244, 243, 243, 1)", fontWeight: "300" }}
                  className="ps--inline-table__value"
                  data-project-partial-target="tokenPrice"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ps--spinner"
                    width="65px"
                    height="65px"
                    viewBox="0 0 66 66"
                  >
                    <circle
                      className="path"
                      fill="none"
                      strokeWidth="6"
                      strokeLinecap="round"
                      cx="33"
                      cy="33"
                      r="30"
                    ></circle>
                  </svg>
                </div>
              )}
              {ido.tradeValue && (
                <div
                  style={{ color: "rgba(244, 243, 243, 1)", fontWeight: "300" }}
                  className="ps--inline-table__value"
                  data-project-partial-target="tokenPrice"
                >
                  {(ido.tradeValue / 10 ** 18).toLocaleString()}{" "}
                  <img src="/images/bnb.png" width="20px" height="20px" />
                </div>
              )}
            </div>
          </div>

          {/* <div data-project-sidebar-target="subscribeFormHolder" className="d-none">
                    <form className="form-floating">
                        <input type="email" className="form-control" id="floatingInputInvalid"
                            placeholder="name@example.com" value="" />
                        <label >Email Address</label>
                    </form>
                    <div className="form-check mt-3">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        <label className="form-check-label" >
                            Also notify me about all news, updates & more from Donor Vaults
                        </label>
                    </div>
                </div> */}
          {mypurchases &&
            mypurchases.length > 0 &&
            mypurchases.map((e) => {
              return (
                <div>
                  <div className="row">
                    <div className="col" style={{ textAlign: "center" }}>
                      Purchase Id
                    </div>
                    <div className="col" style={{ textAlign: "center" }}>
                      Value
                    </div>
                    <div className="col">Date</div>
                  </div>
                  <div className="row">
                    <div
                      className="col"
                      style={{
                        fontWeight: "bold",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      {e.id}
                    </div>
                    <div
                      className="col"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {e["0"] / 10 ** 18}
                    </div>
                    <div className="col">
                      {moment(e["3"] * 1000).format("YYYY-MM-DD")}
                    </div>
                  </div>
                  <div style={{ margin: "auto" }}>
                    <button
                      className="btn btn-success w-100 mt-3"
                      onClick={() => {
                        if (!e["4"]) {
                          handleReedemToken(e.id);
                        } else {
                          console.log("Reedemed");
                        }
                      }}
                    >
                      {e["4"] ? "Reedemed" : "Reedem"}
                    </button>
                  </div>
                </div>

                // <table style={{ textAlign: "center" }}>
                //   <tr style={{ textAlign: "center" }}>
                //     <th style={{ textAlign: "center" }}>Purchase Id</th>
                //     <th style={{ textAlign: "center" }}>Value</th>
                //     <th style={{ textAlign: "center" }}>Date</th>
                //   </tr>
                //   {
                //     <tr style={{ textAlign: "center" }}>
                //       <th style={{ textAlign: "center" }}>{e.id}</th>
                //       <th style={{ textAlign: "center" }}>
                //         {e["0"] / 10 ** 18}
                //       </th>
                //       <th style={{ textAlign: "center" }}>
                //         {moment(e["3"] * 1000).format("YYYY-MM-DD")}
                //       </th>
                //       <th style={{ textAlign: "center" }}>
                // <button
                //   className="btn btn-success w-100 "
                //   onClick={() => {
                //     handleReedemToken(e.id);
                //   }}
                // >
                //   Redeem
                // </button>
                //       </th>
                //     </tr>
                //   }
                // </table>
              );
            })}
          <div
            data-project-sidebar-target="subscribeFormCtas"
            className="d-none"
          >
            <p className="btn btn-primary w-100">Notify me</p>
          </div>
          <div
            className="d-block"
            data-project-sidebar-target="whitelistOpenCtas"
          >
            {!ido.whiteListed && (
              <div
                onClick={() => {
                  window.location.pathname = `/whitelist`;
                  // whitelist();
                  // if (inputToken > 0) { handleApply(inputToken) }
                  // setinvestModal(true);
                  // applyOnIdo(ido?.contractAddress, provider, 1, "0x3A3AC376CE597BCb91925805645F91Bc164bae01")
                }}
                className="ps--project__sidebar__mobile-btn"
              >
                <p className="btn btn-success w-100 mb-3">Apply Now</p>
              </div>
            )}
            {!ido.whiteListed && (
              <div
                onClick={() => {
                  // window.location.pathname = `/whitelist`
                  whitelist();
                  // if (inputToken > 0) { handleApply(inputToken) }
                  // setinvestModal(true);
                  // applyOnIdo(ido?.contractAddress, provider, 1, "0x3A3AC376CE597BCb91925805645F91Bc164bae01")
                }}
                className="ps--project__sidebar__mobile-btn"
              >
                <p
                  onClick={() => {
                    window.location.pathname = "/dashboard";
                  }}
                  className="btn btn-success w-100 mb-3"
                >
                  Go To Dashboard
                </p>
              </div>
            )}
            {ido.started && (
              <div>
                {ido.whiteListed && ido.isOpen && (
                  <input
                    style={{
                      width: "100%",
                      padding: "4px",
                      fontSize: "1.5rem",
                      marginBottom: "30px",
                    }}
                    type="number"
                    onChange={(e) => {
                      setinputToken(e.target.value);
                      handleAmount(e.target.value);
                    }}
                    value={inputToken}
                  />
                )}
                {priceWillPay && (
                  <div
                    style={{
                      color: "#fff",
                      marginBottom: "10px",
                      fontSize: "20px",
                    }}
                  >
                    you will have to pay {priceWillPay / 10 ** 18} BNB
                  </div>
                )}
                {ido.whiteListed && ido.isOpen && (
                  <div
                    onClick={() => {
                      if (inputToken > 0) {
                        handleApply(inputToken);
                      }
                      // applyOnIdo(ido?.contractAddress, provider, 1, "0x3A3AC376CE597BCb91925805645F91Bc164bae01")
                    }}
                    className="ps--project__sidebar__mobile-btn"
                  >
                    <p className="btn btn-success w-100 mb-3">Invest</p>
                  </div>
                )}
                {!ido.isOpen && (
                  <div
                    onClick={() => {
                      // if (inputToken > 0) { handleApply(inputToken) }
                      // applyOnIdo(ido?.contractAddress, provider, 1, "0x3A3AC376CE597BCb91925805645F91Bc164bae01")
                    }}
                    className="ps--project__sidebar__mobile-btn"
                  >
                    <p
                      className="btn btn-success w-100 mb-3"
                      style={{ background: "grey", border: "none" }}
                    >
                      Sale Ended
                    </p>
                  </div>
                )}
              </div>
            )}
            {/* {ido?.startdate * 1000 > Date.now() && <div className="ps--project-card__table">

                            <div style={{ width: "100%", textAlign: "center", color: "#fff", fontWeight: "bold", fontSize: "25px" }}>
                                <Countdown date={Date.now() + (ido?.startdate * 1000 - Date.now())} />
                            </div>


                        </div>} */}
            {/* <p className="btn btn-outline-secondary w-100" >Go to
                        Dashboard</p> */}
          </div>
          {!ido.started && (
            <div>
              <div
                class="ps--project__sidebar__timeleft"
                data-controller="countdown"
                data-id="59"
              >
                <h4 data-countdown-target="prefix">Allowlist Opens in </h4>

                <p class="d-none text-center" id="reloadWarning">
                  Please{" "}
                  <a href="javascript:window.location.reload(true)">
                    reload this page
                  </a>
                </p>
                <div class="ps--countdown" id="js--countdown">
                  <div
                    class="ps--countdown__item"
                    style={{ fontSize: "1.4rem" }}
                  >
                    <Countdown
                      date={Date.now() + (ido.startDate * 1000 - Date.now())}
                      renderer={renderer}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {ido.started && ido.isOpen && (
            <div>
              <div>
                <div
                  class="ps--project__sidebar__timeleft"
                  data-controller="countdown"
                  data-id="59"
                >
                  <h4 data-countdown-target="prefix">Ido Ends in</h4>

                  <p class="d-none text-center" id="reloadWarning">
                    Please{" "}
                    <a href="javascript:window.location.reload(true)">
                      reload this page
                    </a>
                  </p>
                  <div class="ps--countdown" id="js--countdown">
                    {/* <div class="ps--countdown__item" style={{ fontSize: "1.4rem" }}> */}

                    <Countdown
                      date={Date.now() + (ido.endDate * 1000 - Date.now())}
                      renderer={renderer}
                    />
                    {/* </div> */}
                  </div>
                </div>
              </div>
              {/* <Countdown date={Date.now() + (ido.endDate * 1000 - Date.now())} /> */}
            </div>
          )}
          <div
            className="d-none"
            data-project-sidebar-target="whitelistClosedCtas"
          >
            <p className="btn btn-primary w-100 mb-3">Application Status</p>

            <p className="btn btn-outline-secondary btn-icon w-100">
              How to get Whitelisted
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8 13C7.4 13 7 12.6 7 12C7 11.4 7.4 11 8 11C8.6 11 9 11.4 9 12C9 12.6 8.6 13 8 13ZM9.5 8.4C9 8.7 9 8.8 9 9V10H7V9C7 7.7 7.8 7.1 8.4 6.7C8.9 6.4 9 6.3 9 6C9 5.4 8.6 5 8 5C7.6 5 7.3 5.2 7.1 5.5L6.6 6.4L4.9 5.4L5.4 4.5C5.9 3.6 6.9 3 8 3C9.7 3 11 4.3 11 6C11 7.4 10.1 8 9.5 8.4Z"
                  fill="black"
                ></path>
              </svg>
            </p>
          </div>
          <div
            className="d-none"
            data-project-sidebar-target="saleLiveCtas"
            id="joinProjectMainCta"
          ></div>
          <div
            className="d-none"
            data-project-sidebar-target="notWhitelistedCtas"
            id="joinProjectNotWhitelistedCta"
          >
            <div className="ps--project__sidebar__mobile-btn"></div>
          </div>
          <div
            className="d-none"
            data-project-sidebar-target="saleClosedCtas"
          ></div>
          <div style={{ display: "grid", placeItems: "center" }}>
            <div style={{ display: "flex" }}>
              How to get Allowlisted
              <svg
                style={{ margin: "0 0 0 1rem" }}
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10V10ZM10 7C9.8243 6.99983 9.65165 7.04595 9.49945 7.13373C9.34724 7.22151 9.22085 7.34784 9.133 7.5C9.06957 7.61788 8.98311 7.72182 8.87876 7.80566C8.77441 7.8895 8.65429 7.95154 8.52552 7.9881C8.39675 8.02466 8.26194 8.03499 8.12911 8.01849C7.99627 8.00198 7.86809 7.95897 7.75218 7.89201C7.63628 7.82505 7.53499 7.7355 7.45433 7.62867C7.37367 7.52184 7.31529 7.3999 7.28263 7.27008C7.24997 7.14027 7.24371 7.00522 7.26421 6.87294C7.28472 6.74065 7.33157 6.61384 7.402 6.5C7.73222 5.92811 8.24191 5.48116 8.85203 5.22846C9.46214 4.97576 10.1386 4.93144 10.7765 5.10236C11.4143 5.27328 11.978 5.64989 12.38 6.1738C12.782 6.6977 13 7.33962 13 8C13.0002 8.62062 12.8079 9.22603 12.4498 9.73285C12.0916 10.2397 11.5851 10.623 11 10.83V11C11 11.2652 10.8946 11.5196 10.7071 11.7071C10.5196 11.8946 10.2652 12 10 12C9.73478 12 9.48043 11.8946 9.29289 11.7071C9.10536 11.5196 9 11.2652 9 11V10C9 9.73478 9.10536 9.48043 9.29289 9.29289C9.48043 9.10536 9.73478 9 10 9C10.2652 9 10.5196 8.89464 10.7071 8.70711C10.8946 8.51957 11 8.26522 11 8C11 7.73478 10.8946 7.48043 10.7071 7.29289C10.5196 7.10536 10.2652 7 10 7ZM10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13C9.73478 13 9.48043 13.1054 9.29289 13.2929C9.10536 13.4804 9 13.7348 9 14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15V15Z"
                  fill="#E6E6E6"
                />
              </svg>
            </div>
          </div>
        </div>
        {/* <div className="ps--project__sidebar__timeleft d-none" data-controller="countdown" data-id="45">
                <h4 data-countdown-target="prefix"></h4>

                <p className="d-none text-center" id='reloadWarning'>
                    Please <p >reload this page</p>
                </p>
                <div className="ps--countdown" id="js--countdown">
                    <div className="ps--countdown__item">
                        <div className="ps--countdown__value days"></div>
                        <div className="ps--countdown__title">Days</div>
                    </div>
                    <span className="ps--countdown__divider">:</span>
                    <div className="ps--countdown__item">
                        <div className="ps--countdown__value hours"></div>
                        <span className="ps--countdown__title">Hours</span>
                    </div>
                    <span className="ps--countdown__divider">:</span>
                    <div className="ps--countdown__item">
                        <div className="ps--countdown__value minutes"></div>
                        <div className="ps--countdown__title">Minutes</div>
                    </div>
                    <span className="ps--countdown__divider">:</span>
                    <div className="ps--countdown__item">
                        <div className="ps--countdown__value seconds"></div>
                        <div className="ps--countdown__title">Seconds</div>
                    </div>
                </div>
            </div> */}
        {/* <Modal
                    isOpen={investModal}
                    onRequestClose={() => {
                        setinvestModal(false)
                    }}
                    style={customStyles}
                    contentLabel="Swap"
                >
                    <h2 >Hello</h2>
                    <button onClick={() => {
                        setinvestModal(false)
                    }}>close</button>
                    <div>I am a modal</div>
                    <form>
                        <input />
                        <button>tab navigation</button>
                        <button>stays</button>
                        <button>inside</button>
                        <button>the modal</button>
                    </form>
                </Modal> */}
      </div>
    </div>
  );
}

export default Sidebar;
