import * as React from "react";
import DashboardUi from "./DashbordUi";
import Web3 from "web3";
import stakingContract from "../ABI/StakingContract.json";
import tko from "../ABI/tko.json";
import { useWallet } from "use-wallet";
import config from "../config/index";

const Dashboard = () => {
  const [approval, setapproval] = React.useState();
  const [staking_contract, setstaking_contract] = React.useState();
  const [tko_contract, settko_contract] = React.useState();
  const [balance, setbalance] = React.useState();
  const [balancetko, setbalancetko] = React.useState();

  const wallet = useWallet();

  const loadContractData = async () => {
    const web3 = new Web3(Web3.givenProvider);
    const stakingContractObj = new web3.eth.Contract(
      stakingContract,
      config.stakingContract
    );
    console.log(stakingContractObj, "contract 0000000000");
     setstaking_contract(stakingContractObj);

    const tkoContract = await stakingContractObj.methods.TKO().call();
    console.log(tkoContract);

    const balanceOf = await stakingContractObj.methods
      .balanceOf(wallet.account)
      .call();

    setbalance(balanceOf, "balance of");

    const tkoContractObj = new web3.eth.Contract(tko, config.tkoContract);
     settko_contract(tkoContractObj);
    const allowance = await tkoContractObj.methods
      .allowance(wallet.account, config.stakingContract)
      .call();

    const balanceOftko = await tkoContractObj.methods
      .balanceOf(wallet.account)
      .call();

      

     setbalancetko(balanceOftko);

     setapproval(allowance);

    console.log(tkoContractObj, allowance);

    // 0xAf356b653F324D919198e49f24485983331172bB tko contract
  };

  React.useEffect(async () => {
    // wallet.connect();
    if (wallet.status === "connected") {
      await loadContractData();
    }

    console.log();

    return () => {};
  }, [wallet.status, wallet.account, wallet]);
  React.useEffect(() => {
    // wallet.connect();

    return () => {};
  }, []);

  return (
    <div>
      <DashboardUi
        approval={approval}
        staking_contract={staking_contract}
        tko_contract={tko_contract}
        balance={balance}
        balanceOftko={balancetko}
      />
    </div>
  );
};

export default Dashboard;
